import { StackedBars } from '../StackedBars/StackedBars'
import { StackedBars2 } from '../StackedBars2/StackedBars2'
import { MetricsTable } from './MetricsTable'
import { TaskTabs } from '../../Results'

export function TaskBreakdown({
    result,
    caption,
    comparedCaption,
    task,
    setModal,
    gotoTask,
}) {
    const stage = result[task.tab].stages.find(s => s.num === task.stageNum);
    const taskObj = stage.tasks.find(t => t.num === task.taskNum);

    const setTab = tab => {
        const taskNum = result[tab].stages.find(s => s.num === task.stageNum).tasks[0].num;
        gotoTask(tab, task.stageNum, taskNum);
    }

    const setStage = stageNum => {
        const taskNum = result[task.tab].stages.find(s => s.num === stageNum).tasks[0].num;
        gotoTask(task.tab, stageNum, taskNum);
    }

    return <>
        <div className="toggle-switch">
            <div onClick={() => setTab(TaskTabs.BASELINE)} style={{ width: "50%" }} className={"toggle-item" + (task.tab === TaskTabs.BASELINE ? " selected" : "")}>{caption}</div>
            <div onClick={() => setTab(TaskTabs.COMPARED)} style={{ width: "50%" }} className={"toggle-item" + (task.tab === TaskTabs.COMPARED ? " selected" : "")}>{comparedCaption}</div>
        </div>

        <h2 id="task">Task Breakdown</h2>
        <div className="lane-caption">
            Stage
        </div>
        <div className="bars">

            <select value={task.stageNum} onChange={(e) => setStage(Number(e.target.value))}>
                {result[task.tab].stages.map((stage, ix) => <option key={ix} value={stage.num}>
                    Stage {stage.num}
                </option>)}
            </select>
        </div>
        <br />
        <br />
        <div className="lane-caption">
            Task
        </div>
        <div className="bars">
            <select value={task.taskNum} onChange={(e) => gotoTask(task.tab, task.stageNum, Number(e.target.value))}>
                {stage.tasks.map((task, ix) => <option key={ix} value={task.num}>
                    Task {task.num}
                </option>)}
            </select>
        </div>
        <br />
        <br />
        <div className="lane-caption" style={{ paddingTop: "42px" }}>
            Task Timeline
        </div>
        <div className="bars">
            <h3>{task.tab === TaskTabs.BASELINE ? caption : comparedCaption}</h3>
            {taskObj.breakdown.chart().map((lane, ix) => <StackedBars2 key={ix} bars={lane} />)}
            <StackedBars bars={taskObj.axis()} />
        </div>
        <br />
        <br />
        <div className="lane-caption" style={{ paddingTop: "42px" }}>
            &nbsp;
        </div>
        <div className="bars">
            <MetricsTable
                rows={taskObj.breakdown.table()}
                caption="Value"
                setModal={setModal}
            />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
    </>
}
