import React, { useEffect, useState } from 'react'
import './ClusterConfig.css';
import { msToHumanReadable } from './utils';

const ComparedType = {
    BASELINE_APU: "baseline_apu",
    DIFF_CLUSTER: "diff_cluster",
}

export const Compression = {
    UNCOMPRESSED: "uncompressed",
    SNAPPY: "snappy",
    GZIP: "gzip",
    ZSTD: "zstd"
}

const MINI = window.location.href.startsWith("file://");

const duration = (d) => {
    const seconds = Math.floor(d / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    return `${days > 0 ? (days + "d") : ""}${hours % 24}h${minutes % 60}m${seconds % 60}s`;
}

export function ClusterConfig({ baselineParams, setBaseParams, queries, setQueries, comparedParams, setComparedParams, demoFile, raiseError, setModal, fileName }) {
    const [selectedQueryIx, setSelectedQueryIx] = useState(
        demoFile ? queries.all.length - 1 : (
            queries.selected ? queries.all.findIndex(q => q.id === queries?.all[queries.selected]?.id) : undefined
        )
    );
    const [numCoresPerWorker, setNumCoresPerWorker] = useState(baselineParams.params.cluster_worker_num_of_vcpu);
    const [localBandwidth, setLocalBandwidth] = useState(baselineParams.params.local_bandwidth_GBs * 8);
    const [remoteBandwidth, setRemoteBandwidth] = useState(baselineParams.params.remote_bandwidth_GBs * 8);
    const [inputBandwidth, setInputBandwidth] = useState(baselineParams.params.input_bandwidth_GBs * 8);
    const [compression, setCompression] = useState(baselineParams.params.compression_type);
    const [sortBy, setSortBy] = useState(localStorage.getItem("sortBy") || "duration");
    const [sortOrder, setSortOrder] = useState(parseInt(localStorage.getItem("sortOrder")) || -1);
    const [showConfig, setShowConfig] = useState(false);

    const [comparedType, setComparedType] = useState((window.BASELINE_WITH_APU || comparedParams.baselineWithApu) ? ComparedType.BASELINE_APU : ComparedType.DIFF_CLUSTER);

    useEffect(() => {
        if (comparedType === ComparedType.DIFF_CLUSTER) {
            setPendingComparedParams({ ...pendingComparedParams, numOfWorkers: 8 })
        }
    }, [comparedType]);

    const [pendingComparedParams, setPendingComparedParams] = useState({
        baselineWithApu: comparedParams.baselineWithApu,
        apu: comparedParams.apu,
        numOfWorkers: 8,
        numCoresPerWorker: comparedParams.params.cluster_worker_num_of_vcpu,
        localBandwidth: comparedParams.params.local_bandwidth_GBs * 8,
        remoteBandwidth: comparedParams.params.remote_bandwidth_GBs * 8,
        inputBandwidth: comparedParams.params.input_bandwidth_GBs * 8,
        compression: baselineParams.params.compression_type, // always the same as baseline!
        clusterWorkerNumApus: comparedParams.params.cluster_worker_num_apus, // always the same as baseline!
    });

    const go = () => {
        if (showConfig) return setShowConfig(false);
        const pendingBaselineParams = {
            numCoresPerWorker: parseFloat(numCoresPerWorker),
            localBandwidth: parseFloat(localBandwidth),
            remoteBandwidth: parseFloat(remoteBandwidth),
            inputBandwidth: parseFloat(inputBandwidth),
            compression,
        };

        const err = (Object.entries({
            numCoresPerWorker: ["Cores per worker node", 1, true],
            localBandwidth: ["Local Bandwidth", 0, false],
            remoteBandwidth: ["Remote Bandwidth", 0, false],
            inputBandwidth: ["Input Bandwidth", 0, false],
        }).reduce((err, [key, [field, min, mustBeInt]]) => {
            console.log(pendingBaselineParams[key], pendingComparedParams[key], min, mustBeInt);
            return err ||
                (((pendingBaselineParams[key] < min) || (pendingComparedParams[key] < min)) && `${field} must be at least ${min}.`) ||
                (mustBeInt && !(Number.isInteger(parseFloat(pendingBaselineParams[key])) && Number.isInteger(parseFloat(pendingComparedParams[key]))) && `${field} must be an integer.`)
        },
            null));

        if (err) return raiseError(err, false);

        setBaseParams({
            ...baselineParams,
            params: {
                ...baselineParams.params,
                cluster_worker_num_of_vcpu: parseInt(pendingBaselineParams.numCoresPerWorker, 10),
                local_bandwidth_GBs: parseFloat(pendingBaselineParams.localBandwidth) / 8,
                remote_bandwidth_GBs: parseFloat(pendingBaselineParams.remoteBandwidth) / 8,
                input_bandwidth_GBs: parseFloat(pendingBaselineParams.inputBandwidth) / 8,
                cluster_worker_num_apus: parseInt(pendingComparedParams.clusterWorkerNumApus, 10),
                compression_type: pendingBaselineParams.compression,
            }
        });
        setQueries({ ...queries, selected: queries.all[selectedQueryIx].id.toString() });

        console.log('pending cluster_worker_num_apus', parseInt(pendingComparedParams.clusterWorkerNumApus, 10));
        const comparedFilename = "log1";
        setComparedParams({
            ...comparedParams,
            baselineWithApu: comparedType === ComparedType.BASELINE_APU,
            apu: pendingComparedParams.apu,
            params: {
                ...comparedParams.params,
                cluster_worker_num_of_vcpu: parseInt(pendingComparedParams.numCoresPerWorker, 10),
                cluster_num_of_nodes: comparedType === ComparedType.DIFF_CLUSTER ? parseInt(pendingComparedParams.numOfWorkers, 10) + 1 : undefined,
                local_bandwidth_GBs: parseFloat(pendingComparedParams.localBandwidth) / 8,
                remote_bandwidth_GBs: parseFloat(pendingComparedParams.remoteBandwidth) / 8,
                input_bandwidth_GBs: parseFloat(pendingComparedParams.inputBandwidth) / 8,
                cluster_worker_num_apus: parseInt(pendingComparedParams.clusterWorkerNumApus, 10),
                compression_type: pendingBaselineParams.compression,
                logfile: comparedFilename,
                log_pathes: { "0": comparedFilename, "1": comparedFilename },
            },
            test_cold_warm_logfile: comparedFilename,
            test_metawarm_logfile: comparedFilename,
        })
    }

    const showQuery = (e, query) => {
        e.stopPropagation();
        setModal({
            content: <div>
                <h1>Query Description (ID: {query.id})</h1>
                <pre style={{ maxHeight: "500px", overflow: "scroll" }}>{query.sql}</pre>
                <button onClick={() => setModal(null)}>Ok</button>
            </div>
        })
    }

    const sort = (by) => {
        if (by === sortBy) {
            localStorage.setItem("sortOrder", -sortOrder);
            setSortOrder(-sortOrder);
        } else {
            localStorage.setItem("sortOrder", 1);
            localStorage.setItem("sortBy", by);
            setSortBy(by);
            setSortOrder(1);
        }
    }

    const selectQuery = (query, ix) => {
        if (window.INDEX) return window.location.href = query.name;
        (!MINI && query.result?.baseline) ? setQueries({ ...queries, result: query.result }) : setSelectedQueryIx(ix)
    }

    if (selectedQueryIx === -1) return setSelectedQueryIx((demoFile || window.QUERY_ID !== undefined) ? queries.all.length - 1 : undefined);

    console.log(showConfig);

    return <div className="cluster-configuration" style={(selectedQueryIx === undefined && !showConfig) ? { width: "100%", margin: "150px 50px 0 50px" } : {}}>
        {!showConfig && MINI && <button style={{ float: "right", margin: "30px 180px 0 0" }} onClick={() => setShowConfig(true)}>Show Clusters Configuration</button>}
        {!showConfig && <div className="main query-select" style={selectedQueryIx === undefined ? { width: "calc(100% - 150px)" } : {}}>
            {selectedQueryIx === undefined ? <>
                <h1>
                    {MINI ?
                        <>Found {queries.all.length} {queries.all.length === 1 ? "query" : "queries"} in log files:</> :
                        <>Found {queries.all.length} {queries.all.length === 1 ? "query" : "queries"} in log file. Please select a query to analyze:</>
                    }
                </h1>
                <div style={{ height: "calc(100vh - 300px)", overflowY: "scroll", overflowX: "hidden", margin: 0, padding: 0 }}>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "40px", maxWidth: "40px", minWidth: "40px" }} onClick={(e) => sort("id")}>ID {sortBy === "id" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                <th style={{ width: "99px", maxWidth: "99px", minWidth: "99px" }} onClick={(e) => sort("start")}>Time {sortBy === "start" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                <th onClick={(e) => sort("sql")}>Query {sortBy === "sql" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                <th onClick={(e) => sort("filename")}>File {sortBy === "filename" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                <th onClick={(e) => sort("user")}>User {sortBy === "user" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                <th style={{ width: "67px", maxWidth: "67px", minWidth: "67px" }} onClick={(e) => sort("numStages")}>Stages {sortBy === "numStages" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                <th style={{ width: "67px", maxWidth: "67px", minWidth: "67px" }} onClick={(e) => sort("numTasks")}>Tasks {sortBy === "numTasks" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                <th style={{ width: "104px", maxWidth: "104px", minWidth: "104px" }} onClick={(e) => sort("duration")}>Original<br />Duration {sortBy === "duration" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                {MINI ? <>
                                    <th style={{ width: "104px", maxWidth: "104px", minWidth: "104px" }} onClick={(e) => sort("apuDuration")}>APU<br />Duration {sortBy === "apuDuration" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                    <th style={{ width: "67px", maxWidth: "67px", minWidth: "67px" }} onClick={(e) => sort("speedup")}>Speedup {sortBy === "speedup" && <span className="sort-arrow">{sortOrder === 1 ? <>&uarr;</> : <>&darr;</>}</span>}</th>
                                </> : <></>}
                            </tr>
                        </thead>
                        <tbody>
                            {queries.all.sort((a, b) => a[sortBy] > b[sortBy] ? sortOrder : -sortOrder).map((query, ix) => <tr key={ix} className={ix === selectedQueryIx ? "selected" : ""} onClick={() => selectQuery(query, ix)}>
                                <td><div style={{ width: "40px", maxWidth: "40px", minWidth: "40px" }}>{query.id}</div></td>
                                <td><div style={{ width: "99px", maxWidth: "99px", minWidth: "99px" }}>{new Date(query.start).toLocaleString()}</div></td>
                                <td><div style={{ maxWidth: "350px", minWidth: "360px" }}>
                                    <div style={{ minHeight: "60px", maxHeight: "60px", margin: 0, padding: 0, maxWidth: "305px", minWidth: "305px", display: "inline-block" }}>{query.sql}</div>
                                    <div style={{ minHeight: "60px", maxHeight: "60px", margin: 0, padding: "10px 0 0 2px", maxWidth: "25px", minWidth: "25px", display: "inline-block" }}><span onClick={e => showQuery(e, query)} className="info"><div style={{ padding: 0 }}>⋯</div></span></div>
                                </div></td>
                                <td><div>{query.filename === "log1" ? fileName : query.filename}</div></td>
                                <td><div>{query.user}</div></td>
                                <td><div style={{ width: "67px", maxWidth: "67px", minWidth: "67px" }}>{query.numStages}</div></td>
                                <td><div style={{ width: "67px", maxWidth: "67px", minWidth: "67px" }}>{query.numTasks}</div></td>
                                <td><div style={{ minWidth: "104px" }}>{msToHumanReadable(query.duration, true)} {query.concurrent ? <><br />[+{msToHumanReadable(query.concurrent, true)} overhead]</> : ""}</div></td>
                                {MINI ? <>
                                    <td><div style={{ width: "104px", maxWidth: "104px", minWidth: "104px" }}>{msToHumanReadable(query.apuDuration, true)}</div></td>
                                    <td><div style={{ width: "67px", maxWidth: "67px", minWidth: "67px" }}>{query.speedup?.toFixed(1)}x</div></td>
                                </> : <></>}
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </> : <>
                {<>
                    <h1>Selected Query: {queries.all[selectedQueryIx].id}</h1>
                    <div>
                        <div style={{ display: "table-cell ", overflow: "hidden", maxWidth: "900px", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{queries.all[selectedQueryIx].sql}</div>
                        <br />
                        <br />
                        <button style={{ float: "right", marginTop: "-15px" }} onClick={(e) => setSelectedQueryIx(undefined)}>Change</button>
                    </div>
                </>}
            </>}

        </div>}
        {(showConfig || (selectedQueryIx !== undefined)) && <>
            {MINI && <div style={{ textAlign: "left" }}>
                <h1>Clusters Configuration</h1>
                <br />
                <b>The configuration of both clusters is controlled by the <code>wacli</code> command line options</b>
            </div>}
            <div className="main env-config" style={{ marginRight: "40px" }}>
                <h1>Baseline Cluster</h1>
                {demoFile ? <div className="input-container" style={{ marginTop: "8px" }}>
                    <span>A benchmark query is selected. The baseline configuration is set to the values with which the benchmarks were originally run.</span>
                </div> : (!MINI && <div className="input-container">&nbsp;</div>)}
                <span className={demoFile ? "disabled" : (MINI ? "disabled-mini" : "")}>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                Number of worker nodes in the Spark cluster.<br />
                                Automatically detected for the baseline from the logs.
                            </span>
                        </span>&nbsp;
                        <div className="form-label">Worker nodes:</div>
                        <input style={{ opacity: MINI ? 1 : .5 }} disabled={true} type value={MINI ? window.RESULT.queries[0].result.baseline.details.workloadStats.numOfWorkerNodes.value : "automatic"} />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                Number of cores in each worker node.<br />
                                Used to estimate runtime for Spark stages that will not run on the APU
                            </span>
                        </span>&nbsp;
                        <div className="form-label">Cores per worker node:</div>
                        <input disabled={demoFile || MINI} onChange={(e) => setNumCoresPerWorker(e.target.value)} type="number" value={numCoresPerWorker} min="1" />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                The network bandwidth of each worker node, for example 10Gb/s.<br />
                                Used for reading input files, shuffling, and writing output files
                            </span>
                        </span>&nbsp;
                        <div style={{ lineHeight: "15px" }} className="form-label">Network bandwidth per<br /> worker node (Gb/s):</div>
                        <input disabled={demoFile || MINI} onChange={(e) => setRemoteBandwidth(e.target.value)} type="number" value={remoteBandwidth} min="0" />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                The network bandwidth for accessing input and output files, for example to HDFS or S3.<br />
                                In many environments it is less than the worker node network bandwidth.
                            </span>
                        </span>&nbsp;
                        <div style={{ lineHeight: "15px" }} className="form-label">Network bandwidth (Gb/s) to persistent<br /> storage per worker node:</div>
                        <input disabled={demoFile || MINI} onChange={(e) => setInputBandwidth(e.target.value)} type="number" value={inputBandwidth} min="0" />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                The I/O bandwidth to the local disks of the worker nodes.<br />
                                Used for shuffle files and spilling to disk.
                            </span>
                        </span>&nbsp;
                        <div style={{ lineHeight: "15px" }} className="form-label">Local I/O bandwidth (Gb/s) per<br /> worker node:</div>
                        <input disabled={demoFile || MINI} onChange={(e) => setLocalBandwidth(e.target.value)} type="number" value={localBandwidth} min="0" />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                Typical compression of large tables.<br />
                                Used for estimating average row size (affecting APU performance)
                            </span>
                        </span>&nbsp;
                        <div className="form-label">Default Parquet file compression:</div>
                        <select className="narrow" disabled={demoFile || MINI} onChange={(e) => setCompression(e.target.value)} value={compression}>
                            {Object.keys(Compression).map((key) => <option key={key} value={Compression[key]}>{Compression[key] === "uncompressed" ? "none" : Compression[key]}</option>)}
                        </select>
                    </div>
                </span>
            </div>

            <div className="main env-config">
                <h1>Compared Cluster</h1>
                {!MINI && <span>
                    <div className="input-container" style={{ marginTop: "17px" }}>
                        <div className="toggle-switch">
                            <div onClick={() => { setComparedType(ComparedType.BASELINE_APU); setPendingComparedParams({ ...pendingComparedParams, apu: true }) }} className={"toggle-item" + (comparedType === ComparedType.BASELINE_APU ? " selected" : "")} style={{ width: "49.9%" }}>Baseline with APU</div>
                            <div onClick={() => setComparedType(ComparedType.DIFF_CLUSTER)} className={"toggle-item" + (comparedType === ComparedType.DIFF_CLUSTER ? " selected" : "")} style={{ width: "49.9%" }}>Different Cluster</div>
                        </div>
                    </div>
                </span>}
                <span className={comparedType === ComparedType.BASELINE_APU ? "disabled" : (MINI ? "disabled-mini" : "")}>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                Number of worker nodes in the Spark cluster.<br />
                                Automatically detected for the baseline from the logs.
                            </span>
                        </span>&nbsp;
                        <div className="form-label">Worker nodes:</div>
                        {comparedType === ComparedType.DIFF_CLUSTER ?
                            (MINI ?
                                <input value={window.COMPARED_PARAMS.cluster_num_of_nodes ? window.COMPARED_PARAMS.cluster_num_of_nodes - 1 : window.RESULT.queries[0].result.compared.details.workloadStats.numOfWorkerNodes.value} min="1" /> :
                                <input onChange={(e) => setPendingComparedParams({ ...pendingComparedParams, numOfWorkers: e.target.value })} type="number" value={pendingComparedParams.numOfWorkers || "automatic"} min="1" />) :
                            <input disabled={true} type value={"automatic"} />
                        }
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                Number of cores in each worker node.<br />
                                Used to estimate runtime for Spark stages that will not run on the APU
                            </span>
                        </span>&nbsp;
                        <div className="form-label">Cores per worker node:</div>
                        <input disabled={MINI || comparedType === ComparedType.BASELINE_APU} onChange={(e) => setPendingComparedParams({ ...pendingComparedParams, numCoresPerWorker: e.target.value })} type="number" value={comparedType === ComparedType.BASELINE_APU ? numCoresPerWorker : pendingComparedParams.numCoresPerWorker} min="1" />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                The network bandwidth of each worker node, for example 10Gb/s.<br />
                                Used for reading input files, shuffling, and writing output files
                            </span>
                        </span>&nbsp;
                        <div style={{ lineHeight: "15px" }} className="form-label">Network bandwidth per<br />worker node (Gb/s):</div>
                        <input disabled={MINI || comparedType === ComparedType.BASELINE_APU} onChange={(e) => setPendingComparedParams({ ...pendingComparedParams, remoteBandwidth: e.target.value })} type="number" value={comparedType === ComparedType.BASELINE_APU ? remoteBandwidth : pendingComparedParams.remoteBandwidth} min="0" />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                The network bandwidth for accessing input and output files, for example to HDFS or S3.<br />
                                In many environments it is less than the worker node network bandwidth.
                            </span>
                        </span>&nbsp;
                        <div className="form-label" style={{ lineHeight: "15px" }}>Network bandwidth (Gb/s) to persistent<br /> storage per worker node:</div>
                        <input disabled={MINI || comparedType === ComparedType.BASELINE_APU} onChange={(e) => setPendingComparedParams({ ...pendingComparedParams, inputBandwidth: e.target.value })} type="number" value={comparedType === ComparedType.BASELINE_APU ? inputBandwidth : pendingComparedParams.inputBandwidth} min="0" />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                The I/O bandwidth to the local disks of the worker nodes.<br />
                                Used for shuffle files and spilling to disk.
                            </span>
                        </span>&nbsp;
                        <div style={{ lineHeight: "15px" }} className="form-label">Local I/O bandwidth (Gb/s) per<br /> worker node:</div>
                        <input disabled={MINI || comparedType === ComparedType.BASELINE_APU} onChange={(e) => setPendingComparedParams({ ...pendingComparedParams, localBandwidth: e.target.value })} type="number" value={comparedType === ComparedType.BASELINE_APU ? localBandwidth : pendingComparedParams.localBandwidth} min="0" />
                    </div>
                    <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                Typical compression of large tables.<br />
                                Used for estimating average row size (affecting APU performance)
                            </span>
                        </span>&nbsp;
                        <div className="form-label">Default Parquet file compression:</div>
                        <select className="narrow" disabled={true} value={compression}>
                            {Object.keys(Compression).map((key) => <option key={key} value={Compression[key]}>{Compression[key] === "uncompressed" ? "none" : Compression[key]}</option>)}
                        </select>
                    </div>
                    {!MINI && <div className="input-container">
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                Indicates whether the Speedata APU is available in the compared environment.
                            </span>
                        </span>&nbsp;
                        <div className="form-label">Speedata APU:</div>
                        <div onClick={() => comparedType !== ComparedType.BASELINE_APU && setPendingComparedParams({ ...pendingComparedParams, apu: !pendingComparedParams.apu })} className="toggle-switch check" style={{ marginRight: 0 }}>
                            <div className={"toggle-item" + (!pendingComparedParams.apu ? " selected" : "")} style={{ width: "50%" }}>&nbsp;</div>
                            <div className={"toggle-item" + (pendingComparedParams.apu ? " selected checked" : "")} style={{ width: "50%" }}>&nbsp;</div>
                        </div>
                    </div>}
                </span>
                <span className={(!MINI && (comparedType === ComparedType.DIFF_CLUSTER ? !pendingComparedParams.apu : demoFile)) ? "disabled" : (MINI ? "disabled-mini" : "")}>
                    <div className="input-container" style={{ marginTop: "15px" }}>
                        <span className="info tooltip">i
                            <span className="tooltiptext">
                                Indicates how many APUs are present in each worker node.
                            </span>
                        </span>&nbsp;
                        <div className="form-label">Number of APUs:</div>
                        <input disabled={MINI || (comparedType === ComparedType.DIFF_CLUSTER ? !pendingComparedParams.apu : demoFile)} onChange={(e) => setPendingComparedParams({ ...pendingComparedParams, clusterWorkerNumApus: e.target.value })} type="number" value={pendingComparedParams.clusterWorkerNumApus} min="1" />
                    </div>
                </span>
            </div>
            {showConfig ?
                <><br /><button style={{ display: "block", margin: "20px 0 0 470px" }} onClick={() => setShowConfig(false)}>{MINI ? "Back" : "OK"}</button></> :
                <button onClick={() => go()}>Apply</button>
            }
        </>}
    </div >
}
