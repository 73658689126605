import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

window.onscroll = e => {
  const y = window.scrollY;
  const stageY = document.getElementById("stage")?.getBoundingClientRect().y + y;
  const taskY = document.getElementById("task")?.getBoundingClientRect().y + y;
  if (!stageY || !taskY) return;
  document.getElementById("nav-item-task").classList[y >= taskY ? "add" : "remove"]("selected");
  document.getElementById("nav-item-stage").classList[y >= stageY && window.scrollY < taskY ? "add" : "remove"]("selected");
  document.getElementById("nav-item-query").classList[y < stageY ? "add" : "remove"]("selected");
  document.getElementById("nav").style.top = (y < 103 ? 103 - y : -3) + "px";
}

window.VERSION = window.VERSION || await fetch('version').then(res => res.text()).then(text => text.trim());
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
