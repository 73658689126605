import React, { useState, useEffect } from 'react'
import { StackedBars } from '../StackedBars/StackedBars'
import { StackedBars2 } from '../StackedBars2/StackedBars2'
import { msToHumanReadable } from '../../utils'
import { MetricsTable } from './MetricsTable'
import { TaskTabs } from '../../Results'
import { Strings } from 'waschema';

export function StageBreakdown({
    result,
    setStageNum,
    caption,
    comparedCaption,
    stageNum,
    setModal,
    gotoTask
}) {
    const [alt, setAlt] = useState(false);

    const baselineStage = result.baseline.stages.find(s => s.num === stageNum);
    const comparedStage = result.compared.stages.find(s => s.num === stageNum);
    const taskRatio = baselineStage.tasks.length / comparedStage.tasks.length;
    const baselineAvgTask = baselineStage.tasks.length < 200 && (baselineStage.tasks.reduce((acc, task) => acc + task.end - task.start, 0) / baselineStage.tasks.length * taskRatio);
    const comparedAvgTask = baselineStage.tasks.length < 200 && (comparedStage.tasks.reduce((acc, task) => acc + task.end - task.start, 0) / comparedStage.tasks.length);
    const baselineTaskChart = baselineStage.tasks.length < 200 && baselineStage.componentChart();
    const comparedTaskChart = baselineStage.tasks.length < 200 && comparedStage.componentChart();

    const [baselineTaskChartHeight, setBaselineTaskChartHeight] = useState(Math.min(180, baselineTaskChart.length * 15));
    const [comparedTaskChartHeight, setComparedTaskChartHeight] = useState(Math.min(180, comparedTaskChart.length * 15));

    useEffect(() => {
        setBaselineTaskChartHeight(Math.min(180, baselineTaskChart.length * 15));
        setComparedTaskChartHeight(Math.min(180, comparedTaskChart.length * 15));
    }, [baselineStage, comparedStage]);

    const showNotAccelerated = (e, extent, reasons) => {
        e.stopPropagation();
        setModal({
            content: <div>
                <h1>Why is this stage {extent} accelerated?</h1>
                {reasons.map((item, ix) => <div key={ix}>
                    <h3>{Strings[item.reason]}:</h3>
                    <ul style={{ marginLeft: "20px" }}>
                        {item.details.map((detail, ix) => <li key={ix}>{detail}</li>)}
                    </ul>
                    <br />
                </div>)}
                <button onClick={() => setModal(null)}>Ok</button>
            </div>
        })
    }

    const gotoBaselineTask = taskNum => {
        gotoTask(TaskTabs.BASELINE, stageNum, taskNum);
    }

    const gotoComparedTask = taskNum => {
        gotoTask(TaskTabs.COMPARED, stageNum, taskNum);
    }

    const gotoBaselineTaskByIx = ix => {
        gotoTask(TaskTabs.BASELINE, stageNum, baselineStage.tasks[ix].num);
    }

    const gotoComparedTaskByIx = ix => {
        gotoTask(TaskTabs.COMPARED, stageNum, baselineStage.tasks[ix].num);
    }

    return <>
        <br />
        <div className="lane-caption">
            Stage
        </div>
        <div className="bars">
            <select value={baselineStage.num} onChange={(e) => setStageNum(Number(e.target.value))}>
                {result.baseline.stages.map((stage, ix) => <option key={ix} value={stage.num}>
                    Stage {stage.num}
                </option>)}
            </select>
        </div >
        {comparedStage.notAccelerated.length > 0 && <div className="not-accelerated"><span className="info static">!</span> &nbsp;This stage was {comparedStage.notSupported() ? "not" : "partially"} accelerated by the Speedata APU (<a href="javascript://" onClick={(e) => showNotAccelerated(e, comparedStage.notSupported() ? "not" : "partially", comparedStage.notAccelerated)}>learn why</a>)</div>}
        {baselineStage.breakdown.concurrent.value > 0 && <div className="not-accelerated"><span className="info static">!</span> &nbsp;There were concurrent tasks from other queries within this application (this Spark Job) during the execution of this stage.</div>}

        <div className="comparison">
            <div className="lane-caption" style={{ paddingTop: "60px" }}>
                Stage<br />Timeline
            </div>
            <div className="bars">
                <br />
                <h3>Baseline</h3>
                {baselineStage.breakdown.chart().map((lane, ix) => <StackedBars2 key={ix} bars={lane} />)}
                <StackedBars className={"boop bad"} bars={baselineStage.axis()} />
            </div>
            <br />
            <br />
            <div className="lane-caption" style={{ paddingTop: "60px" }}>
                Stage<br />Timeline
            </div>
            <div className="bars">
                <br />
                <h3>{comparedCaption}</h3>
                {comparedStage.breakdown.chart().map((lane, ix) => <StackedBars2 key={ix} bars={lane} />)}
                <StackedBars className={"boop good"} bars={{ ...comparedStage.axis(), altScale: comparedStage.axis().scale }} />
            </div>
            <br />
            <br />
        </div>
        <div className="lane-caption">
        </div>
        <br />
        <div className="lane-caption" style={{ marginTop: "24px" }}></div>
        <div className="bars">
            <MetricsTable
                rows={baselineStage.breakdown.table()}
                comparedRows={comparedStage.breakdown.table()}
                caption="Baseline"
                comparedCaption={comparedCaption}
                setModal={setModal}
            />
        </div>
        <br />

        <h2>Task statistics summary – Baseline ({baselineStage.tasks.length} tasks)</h2>
        <br />
        <MetricsTable
            tableData={baselineStage.taskStatsSummaryTable()}
            setModal={setModal}
            clickFn={gotoBaselineTask}
        />
        <h2>Task statistics summary – Baseline with APU ({comparedStage.tasks.length} tasks)</h2>
        <br />
        <MetricsTable
            tableData={comparedStage.taskStatsSummaryTable()}
            setModal={setModal}
            clickFn={gotoComparedTask}
        />
        <br />
        {baselineStage.tasks.length < 200 ? <>
            <div className="lane-caption" style={{ paddingTop: "10px" }}>{caption}</div>
            <div className="bars accordion" style={{ maxHeight: baselineTaskChartHeight + "px", minHeight: baselineTaskChart.length > 12 ? baselineTaskChartHeight + "px" : undefined }}>
                <div> {baselineTaskChart.map((bars, ix) => (
                    <StackedBars key={ix} className={ix % 2 ? "" : "alt-lane"} bars={{ ...bars, altScale: comparedStage.scale, alt, setAlt }} height={15} onBarClick={gotoBaselineTask} />
                ))} </div>
                {baselineTaskChart.length > 12 && <div className="accordion-toggle">
                    <span className="switch baseline" onClick={() => setBaselineTaskChartHeight(baselineTaskChartHeight === 180 ? baselineTaskChart.length * 15 + 40 : 180)}>
                        <div className={"arrow " + (baselineTaskChartHeight === 180 ? "down" : "up")}>&nbsp;</div></span>
                </div>}
            </div>
            <div className="lane-caption" style={{ marginTop: "-5px" }}></div>
            <div className="bars" style={{ margin: "-5px 0" }}>
                <div style={{ borderLeft: "1px solid #9bb0bb", fontSize: "16px", padding: (baselineTaskChart.length > 12 ? "5" : "20") + "px 20px 20px 20px" }}>
                    {comparedStage.notAccelerated.length === 0 ? <>
                        On average, <span style={{ color: "rgb(204, 51, 51)", fontWeight: 800 }}>{taskRatio.toFixed(1)} CPU tasks taking {msToHumanReadable(baselineAvgTask)}</span> were merged and accelerated by <b>{(baselineAvgTask / comparedAvgTask).toFixed(1)}x</b> into <span style={{ color: "rgb(19, 51, 73)", fontWeight: 800 }}>1 APU task taking {msToHumanReadable(comparedAvgTask)}</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;<button onClick={() => setAlt(!alt)} style={{ backgroundColor: "rgb(204, 51, 51)", padding: "5px", border: "1px solid #ccc", borderRadius: "15px", color: "#fff", fontWeight: 600 }}>Show me</button>
                    </> : <i>Stage is not accelerated by the Speedata APU.</i>}
                </div>
            </div>
            <div className="lane-caption" style={{ marginTop: "0px" }}>{comparedCaption}</div>
            <div className="bars accordion" style={{ marginBottom: "-5px" }}>
                <div className="white-caption"> {comparedTaskChart.map((bars, ix) => (
                    <StackedBars key={ix} className={ix % 2 ? "" : "alt-lane"} bars={{ ...bars, altScale: comparedStage.scale, scale: baselineStage.scale, alt, setAlt }} height={15} onBarClick={gotoComparedTask} />
                ))} </div>
                {comparedTaskChart.length > 12 && <div className="accordion-toggle">
                    <span className="switch compared" onClick={() => setComparedTaskChartHeight(comparedTaskChartHeight === 180 ? comparedTaskChart.length * 15 + 40 : 180)}>
                        <div className={"arrow " + (comparedTaskChartHeight === 180 ? "down" : "up")}>&nbsp;</div>
                    </span>
                </div>}
            </div>
            <div className="lane-caption"></div>
            <div className="bars">
                <StackedBars className={"boop good"} bars={{ ...baselineStage.axis(comparedStage.scale), alt, setAlt }} />
            </div>
        </> : <>
            <i>Task timeline not shown due to stage having too many tasks.</i>
        </>}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
    </>
}
