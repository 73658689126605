import React from 'react'
import './StackedBars.css'

export const BarTypes = {
    BAR: 'bar',
    GAP: 'gap',
    PLACEHOLDER: 'placeholder',
}

export const msToString = (ms) => {
    const seconds = ms / 1000;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const millis = Math.floor((seconds % 1) * 1000);

    let str = `${(hours ? hours + "h" : "")}${((minutes || hours) ? minutes + "m" : "")}${secs}s`;
    if ([2, 3].includes(str.length)) str = `${(secs + millis / 1000).toFixed(1)}s`;
    if (str.startsWith("0.")) str = `${millis}ms`;
    return str;
}

export function StackedBars({ className, bars, caption, height = 60, onBarClick = null }) {
    const { axis, altScale, scale, data, gaps, alt, setAlt } = bars;
    const timeAxis = axis
        ? {
            true: Array.from(Array(11)).reduce((all, _, ix) => [...all, msToString(parseFloat(altScale / 10 * ix))], []),
            false: Array.from(Array(11)).reduce((all, _, ix) => [...all, msToString(parseFloat(scale / 10 * ix))], []),
        }
        : null;

    const pctWithoutGaps = 100 - data.filter(bar => bar.type === BarTypes.GAP).reduce((sum, bar) => sum + bar.value, 0);
    let out = data.reduce((all, bar, ix) => {
        const classNames = [
            bar.type || BarTypes.BAR,
            ...(gaps ? ["gaps"] : []),
            ...(bar.classNames || []),
        ].join(' ');

        const pct = bar.value / (alt ? altScale : scale) * (bar.type === BarTypes.GAP ? 100 : pctWithoutGaps);
        const px = bar.type === BarTypes.GAP
            ? 0
            : gaps
                ? [0, data.length - 1].includes(ix) ? 5 : 10
                : 0
            ;

        if (pct <= 0) return all;

        const width = `calc( ${pct}% - ${px}px )`;

        const style = {
            width: width,
            height: className === "inner" ? `${height}px` : `calc(${height}px / 6 * 4)`,
            marginTop: className === "inner" ? 0 : `calc(${height}px / 6)`,
            marginBottom: className === "inner" ? 0 : `calc(${height}px / 6)`,
            ...(bar.color ? { backgroundColor: bar.color } : {}),
            ...(bar.placeholder ? { visibility: "hidden" } : {}),
            ...((onBarClick && bar.clickValue) ? { cursor: "pointer", pointerEvents: "auto" } : {})
        }

        if (bar.innerBars) {
            const caption = pct > 5 ? bar.innerBars.caption : bar.innerBars.caption.split(" ")[1];
            bar.inner = <StackedBars className="inner" bars={bar.innerBars} caption={caption} height={height / 6 * 4} />;
        }

        return [...all, {
            type: bar.type,
            className: classNames,
            style: style,
            inner: bar.inner,
            caption: pct > 1 && bar.caption,
            onClick: onBarClick && bar.clickValue && (() => onBarClick(bar.clickValue)),
            tooltip: bar.tooltip,
            color: bar.color,
        }]
    }, []);

    return <>
        {!timeAxis && <div className={"stacked-bars lane " + className} style={{ height: height + "px" }}>
            {caption && height > 19 && <div className="caption-wrapper">
                <div className="caption">{caption}</div>
            </div>}
            <div className={"bars-wrapper" + (alt ? " alt" : "")}>
                {out.map((bar, i) => <div key={i} className={bar.className} style={bar.style} onClick={bar.onClick}>
                    {bar.inner}
                    {bar.caption && <div className="caption" style={{ fontSize: Math.min(height / 2, 16) + "px", marginTop: height < 20 ? "-2px" : null }}>{bar.caption}</div>}
                    {bar.type === BarTypes.GAP && <div className="gap-inner">&nbsp;</div>}
                    {bar.tooltip && <span className="tooltiptext" style={{ backgroundColor: bar.color, "--tooltip-color": bar.color }}>{bar.tooltip}</span>}
                </div>)}
            </div>
        </div>
        }
        {
            timeAxis && <div className={"stacked-bars axis " + className}>
                <div className="scale-container" style={{ width: (alt ? scale / altScale * 910 : 910) + "px", opacity: alt ? 0 : 1 }}>
                    {timeAxis[false].map((ts, ix) => ix > 0 && ix < timeAxis[false].length - 1 && <div className="tick" key={ix}>
                        <div className="mark" />
                        <div>
                            {ix === 1 && <div className="timestamp" style={{ float: "left", marginLeft: "-.5em" }}>0</div>}
                            <div className="timestamp"><div className="timestamp-inner">{ts}</div></div>
                        </div>
                    </div>)}
                </div>
                <div className="tick floating" onClick={() => altScale && setAlt(!alt)} style={{ left: (alt ? scale / altScale * 909 : 909) + "px" }}>
                    <div className="mark" />
                    <div className="timestamp scale-marker"><div className="timestamp-inner bad">{msToString(scale)}</div></div>
                </div>
                {altScale && <>
                    <div className="scale-container" style={{ width: (alt ? 910 : altScale / scale * 910) + "px", opacity: alt ? 1 : 0 }}>
                        {timeAxis[true].map((ts, ix) => ix > 0 && ix < timeAxis[true].length - 1 && <div className="tick" key={ix}>
                            <div className="mark" />
                            <div>
                                {ix === 1 && <div className="timestamp" style={{ float: "left", marginLeft: "-.5em" }}>0</div>}
                                <div className="timestamp"><div className="timestamp-inner">{ts}</div></div>
                            </div>
                        </div>)}
                    </div>
                    <div className="tick floating" onClick={() => setAlt(!alt)} style={{ left: (alt ? 909 : altScale / scale * 909) + "px" }}>
                        <div className="mark" />
                        <div className="timestamp scale-marker alt"><div className="timestamp-inner good">{msToString(altScale)}</div></div>
                    </div>
                </>}
            </div>
        }
    </>
}
