import { toTableRow } from "./elements/Detailed/MetricsTable";
import { BarTypes } from "./elements/StackedBars/StackedBars";
import {
    Metric,
    Analysis as SchemaAnalysis,
    QueryDetails,
    Stage as SchemaStage,
    StageTaskBreakdown as SchemaStageTaskBreakdown,
    StageTaskExecution,
    Summary,
    Task as SchemaTask,
    Result as SchemaResult,
    NotAccelerated,
    WaSchema as SchemaWaSchema,
    Query as SchemaQuery,
    MetricUnit,
    TaskPercentiles,
    Percentiles,
} from "waschema";

type Params = {
    withApu: boolean;
    comparedLog: boolean;
};

enum Env {
    BASELINE = "baseline",
    COMPARED = "compared",
}

const getPercentile = (sortedArr: number[], p: number): number => {
    const index = (p / 100) * (sortedArr.length - 1);
    const lower = Math.floor(index);
    const upper = Math.ceil(index);
    const weight = index - lower;
    if (upper >= sortedArr.length) return sortedArr[lower];
    return sortedArr[lower] * (1 - weight) + sortedArr[upper] * weight;
};

const calcStats = (arr: number[]): number[] => {
    if (arr.length === 0) return [];

    const sortedArr = arr.slice().sort((a, b) => a - b);

    return [
        sortedArr[0],
        getPercentile(sortedArr, 25),
        getPercentile(sortedArr, 50),
        getPercentile(sortedArr, 75),
        getPercentile(sortedArr, 99),
        sortedArr[sortedArr.length - 1]
    ];
}

abstract class ComponentChart {
    components: (Stage | Task)[];
    scale: number;
    env: Env;
    result: Result;

    componentChart(captionPrefix: string | null = null) {
        const stageLanes: { scale: number, gaps: true, data: any[] }[] = [];
        const rangeMaps: ({ start: number, end: number } | Stage)[][] = [];
        this.components.sort((a, b) => a.start - b.start);
        this.components.forEach((component) => {
            let ix = rangeMaps.findIndex((lane) => (
                // Find a lane that has no overlap with this stage
                [...lane, component].reduce(({ freeRanges, success }, component) => {
                    const [start, end] = [component.start, component.end];
                    let freeRange = freeRanges.find(range => start >= range.start && end < range.end);
                    if (freeRange) {
                        freeRanges.push({ start: end, end: freeRange.end });
                        freeRange.end = start;
                        return { freeRanges, success: true };
                    }
                    return { freeRanges, success: false };
                }, { freeRanges: [{ start: 0, end: this.scale }], success: false }).success
            ));

            ix = ix > -1 ? ix : rangeMaps.length;
            rangeMaps[ix] = rangeMaps[ix] || [];
            rangeMaps[ix].push(component);

            if (component.breakdown.total.value / this.scale * 100 < 1) return;

            const lane = stageLanes[ix] = stageLanes[ix] || {
                scale: this.scale,
                gaps: true,
                data: [],
            };

            lane.data.push({
                value: component.start - lane.data.reduce((sum, s) => sum += s.value, 0),
                color: "rgba(255, 255, 255, 0)",
                type: BarTypes.PLACEHOLDER,
            });

            lane.data.push({
                value: component.end - component.start,
                color: component.color() || (this.env === Env.BASELINE ? "rgb(204, 51, 51)" : (this.result.flip ? "rgb(204, 51, 51)" : "#133349")),
                caption: (captionPrefix ? captionPrefix + " " : "") + component.num,
                clickValue: component.num,
                classNames: ["tooltip"],
                tooltip: component.tooltip(),
            });
        });
        return stageLanes.filter(lane => lane);
    }

    axis(altScale: number | null = null) {
        return { axis: true, scale: this.scale, altScale, data: [] }
    }
}

class StageTaskBreakdown implements SchemaStageTaskBreakdown {
    deserialization: Metric;
    execution: StageTaskExecution;
    scheduling: Metric;
    serialization: Metric;
    total: Metric;
    query: Analysis;
    concurrent: Metric;

    constructor(schemaStageTaskBreakdown: SchemaStageTaskBreakdown, query: Analysis) {
        this.deserialization = schemaStageTaskBreakdown.deserialization;
        this.execution = schemaStageTaskBreakdown.execution;
        this.scheduling = schemaStageTaskBreakdown.scheduling;
        this.serialization = schemaStageTaskBreakdown.serialization;
        this.total = schemaStageTaskBreakdown.total;
        this.concurrent = schemaStageTaskBreakdown.concurrent;
        this.query = query;
    }

    chart() {
        const duration = (this.total.value - this.concurrent.value) || 0;
        const computePath = (this.execution.computePath.fetchWait.value || 0) + (this.execution.computePath.compute.value || 0) + (this.execution.computePath.writeWait.value || 0);
        const local = (this.execution.localIo.readTime.value || 0) + (this.execution.localIo.writeTime.value || 0);
        const remote = this.execution.remoteIo.readTime.value || 0;
        const storage = (this.execution.persistentStorageIo.readTime.value || 0) + (this.execution.persistentStorageIo.writeTime.value || 0);
        const exec = Math.max(computePath, local, remote, storage);
        let lane1 = {
            scale: duration,
            gaps: true,
            data: [
                { value: this.scheduling.value || 0, gaps: true, color: "rgb(228, 146, 52)", font: "#133349", caption: "Scheduling" },
                { value: this.deserialization.value || 0, color: "rgb(228, 146, 52)", font: "#133349", caption: "Deserialization" },
                { value: this.execution.computePath.fetchWait.value || 0, color: "rgb(201, 201, 201)", caption: "Fetch Wait Time" },
                { value: this.execution.computePath.compute.value || 0, color: this.query.env === Env.BASELINE || this.query.result.flip ? "rgb(196, 41, 48)" : "rgb(25, 65, 92)", caption: "Compute" },
                { value: this.execution.computePath.writeWait.value || 0, color: "rgb(201, 201, 201)", caption: "Write Wait Time" },
                { value: parseFloat((exec - computePath).toFixed(3)), placeholder: true },
                { value: this.serialization.value || 0, color: "rgb(228, 146, 52)", font: "#133349", caption: "Serialization" },
            ],
        };

        let snip = 0;
        let max = 0;
        let largest = 0;
        lane1.data.forEach((bar, ix) => {
            if (bar.value === 0) return;
            if (!bar.placeholder && bar.value < duration / 20) {
                snip += duration / 20 - bar.value;
                bar.value = duration / 20;
            } else if (bar.value > max) {
                max = bar.value;
                largest = ix;
            }
        });
        lane1.data[largest].value -= snip;
        return [
            lane1,
            {
                scale: duration,
                gaps: true,
                data: [
                    { value: lane1.data[0].value + lane1.data[1].value, placeholder: true },
                    {
                        value: Math.min(remote, duration - lane1.data[0].value - lane1.data[1].value - lane1.data[6].value),
                        color: this.query.env === Env.BASELINE || this.query.result.flip ? "rgb(201, 79, 98)" : "rgb(63, 104, 127)",
                        caption: "Remote Shuffle"
                    },
                ],
            }, {
                scale: duration,
                gaps: true,
                data: [
                    { value: lane1.data[0].value + lane1.data[1].value, placeholder: true },
                    {
                        value: Math.min(local, duration - lane1.data[0].value - lane1.data[1].value - lane1.data[6].value),
                        color: this.query.env === Env.BASELINE || this.query.result.flip ? "rgb(209, 141, 153)" : "rgb(108, 144, 161)",
                        caption: "Local Shuffle"
                    },
                ],
            }, {
                scale: duration,
                classNames: [],
                gaps: true,
                data: [
                    { value: lane1.data[0].value + lane1.data[1].value, placeholder: true },
                    {
                        value: Math.min(storage, duration - lane1.data[0].value - lane1.data[1].value - lane1.data[6].value),
                        color: this.query.env === Env.BASELINE || this.query.result.flip ? "rgb(221, 206, 209)" : "rgb(153, 183, 196)",
                        caption: "Persistent Storage"
                    },
                ],
            }
        ];
    }

    computePathTime() {
        return this.execution.computePath.fetchWait.value + this.execution.computePath.compute.value + this.execution.computePath.writeWait.value;
    }

    localTime() {
        return this.execution.localIo.readTime.value + this.execution.localIo.writeTime.value;
    }

    remoteTime() {
        return this.execution.remoteIo.readTime.value;
    }

    storageTime() {
        return this.execution.persistentStorageIo.readTime.value + this.execution.persistentStorageIo.writeTime.value;
    }

    execTime() {
        return Math.max(this.computePathTime(), this.localTime(), this.remoteTime(), this.storageTime());
    }

    table() {
        return [
            toTableRow(this.total, "Total Time"),
            toTableRow(this.concurrent, "Executor Busy Time"),
            toTableRow(this.scheduling, "Scheduling Time"),
            toTableRow(this.deserialization, "Deserialization Time"),

            toTableRow({ value: this.execTime(), unit: "MS" }, "Execution", "Execution"),
            toTableRow({ value: this.computePathTime(), unit: "MS" }, "Compute Path", "ComputePath", "Execution"),
            toTableRow(this.execution.computePath.fetchWait, "Fetch Wait Time", null, "ComputePath"),
            toTableRow(this.execution.computePath.compute, "Compute", null, "ComputePath"),
            toTableRow(this.execution.computePath.writeWait, "Write Wait Time", null, "ComputePath"),

            toTableRow({ value: this.storageTime(), unit: "MS" }, "Persistent Storage I/O", "StorageIO", "Execution"),
            toTableRow(this.execution.persistentStorageIo.readTime, "Read Time", null, "StorageIO"),
            toTableRow(this.execution.persistentStorageIo.bytesRead, "Bytes Read", null, "StorageIO"),
            toTableRow(this.execution.persistentStorageIo.writeTime, "Write Time", null, "StorageIO"),
            toTableRow(this.execution.persistentStorageIo.bytesWritten, "BytesWritten", null, "StorageIO"),

            toTableRow(this.execution.remoteIo.readTime, "Remote I/O", "RemoteIO", "Execution"),
            toTableRow(this.execution.remoteIo.readTime, "Read Time", null, "RemoteIO"),
            toTableRow(this.execution.remoteIo.bytesRead, "Bytes Read", null, "RemoteIO"),

            toTableRow({ value: this.localTime(), unit: "MS" }, "Local I/O", "LocalIO", "Execution"),
            toTableRow(this.execution.localIo.readTime, "Read Time", null, "LocalIO"),
            toTableRow(this.execution.localIo.bytesRead, "Bytes Read", null, "LocalIO"),
            toTableRow(this.execution.localIo.writeTime, "Write Time", null, "LocalIO"),
            toTableRow(this.execution.localIo.bytesWritten, "BytesWritten", null, "LocalIO"),

            toTableRow(this.serialization, "Serialization Time")
        ]

    }
}

export class Task implements SchemaTask {
    breakdown: StageTaskBreakdown;
    end: number;
    num: number;
    start: number;
    scale: number;
    stage: Stage;
    query: Analysis;

    constructor(schemaTask: SchemaTask, stage: Stage) {
        this.breakdown = new StageTaskBreakdown(schemaTask.breakdown, stage.query);
        this.num = schemaTask.num;
        this.start = schemaTask.start - stage.start;
        this.end = schemaTask.end - stage.start;
        this.scale = schemaTask.breakdown.total.value;
        this.stage = stage;
    }

    axis() {
        return { axis: true, scale: this.scale, data: [] }
    }

    color() { }

    tooltip() { }
}

export class Stage extends ComponentChart implements SchemaStage {
    breakdown: StageTaskBreakdown;
    end: number;
    num: number;
    start: number;
    tasks: Task[];
    query: Analysis;
    components: Task[];
    result: Result;
    notAccelerated: NotAccelerated[];
    taskPercentiles: TaskPercentiles;

    constructor(schemaStage: SchemaStage, env: Env, query: Analysis) {
        super();
        this.breakdown = new StageTaskBreakdown(schemaStage.breakdown, query);
        this.end = schemaStage.end;
        this.num = schemaStage.num;
        this.start = schemaStage.start;
        this.query = query;
        this.components = this.tasks = schemaStage.tasks.map(task => new Task(task, this));
        this.env = env;
        this.scale = this.breakdown.total.value;
        this.result = query.result;
        this.notAccelerated = schemaStage.notAccelerated;
        this.taskPercentiles = schemaStage.taskPercentiles;
    }

    color() {
        if (this.env === Env.COMPARED && this.notAccelerated.length > 0) return this.notSupported() ? "#888" : "rgba(19, 51, 73, .8)";
    }

    tooltip() {
        if (this.env === Env.COMPARED) return this.notSupported() ? "Not" : (this.partiallySupported() ? "Partially" : "Hardware") + " Accelerated";
    }

    taskStatsSummaryRow(metrics: [string, Percentiles][]) {
        return metrics.map(([caption, pcts]) => ({
            caption,
            unit: pcts.unit,
            values: [
                pcts.min,
                pcts.pct25,
                pcts.median,
                pcts.pct75,
                pcts.pct99,
                pcts.max,
            ],
            clickArgs: [
                this.tasks[pcts.min_ix].num,
                this.tasks[pcts.pct25_ix].num,
                this.tasks[pcts.median_ix].num,
                this.tasks[pcts.pct75_ix].num,
                this.tasks[pcts.pct99_ix].num,
                this.tasks[pcts.max_ix].num,
            ]
        }))
    }

    taskStatsSummaryTable() {
        return {
            headers: ["Min", "25th Pctl", "Median", "75th Pctl", "99th Pctl", "Max"],
            rows: [
                this.taskStatsSummaryRow([["Total Time", this.taskPercentiles.total]]),
                this.taskStatsSummaryRow([["Scheduling", this.taskPercentiles.scheduling]]),
                this.taskStatsSummaryRow([["Deserialization", this.taskPercentiles.deserialization]]),
                this.taskStatsSummaryRow([["Execution", this.taskPercentiles.execution]]),
                this.taskStatsSummaryRow([["Serialization", this.taskPercentiles.serialization]]),
                this.taskStatsSummaryRow([["Input Read", this.taskPercentiles.inputRead], ["Rows", this.taskPercentiles.inputReadRows]]),
                this.taskStatsSummaryRow([["Output Written", this.taskPercentiles.outputWritten], ["Rows", this.taskPercentiles.outputWrittenRows]]),
                this.taskStatsSummaryRow([["Shuffle Read", this.taskPercentiles.shuffleRead], ["Rows", this.taskPercentiles.shuffleReadRows]]),
                this.taskStatsSummaryRow([["Shuffle Written", this.taskPercentiles.shuffleWritten], ["Rows", this.taskPercentiles.shuffleWrittenRows]]),
            ]
        }
    }

    partiallySupported() {
        return this.notAccelerated.length > 0 && !this.notAccelerated.find(n => n.reason === "NOT_ACCELERATED__UNSUPPORTED_NODE")
    }

    notSupported() {
        return this.notAccelerated.length > 0 && this.notAccelerated.find(n => n.reason === "NOT_ACCELERATED__UNSUPPORTED_NODE")
    }
}

export class Analysis extends ComponentChart implements SchemaAnalysis {
    details: QueryDetails;
    stages: Stage[];
    summary: Summary;
    env: Env;
    result: Result;
    components: Stage[];

    constructor(schemaQuery: SchemaAnalysis, env: Env, result: Result) {
        super()
        this.result = result;
        this.details = schemaQuery.details;
        this.summary = schemaQuery.summary;
        this.components = this.stages = schemaQuery.stages.map(s => new Stage(s, env, this));
        this.env = env;
        this.scale = this.summary.time.total.value;
        // this.offset = this.stages[0].start;
    }

    timeBreakdownTable() {
        return [
            toTableRow(this.details.timeBreakdown.compute, "Compute Time Duration"),
            toTableRow(this.details.timeBreakdown.driverOverhead, "Driver Overhead", "DriverOverhead"),
            toTableRow(this.details.timeBreakdown.driverOverheadTimes.queryStartup, "Query Startup Time", null, "DriverOverhead"),
            toTableRow(this.details.timeBreakdown.driverOverheadTimes.queryClosing, "Query Closing Time", null, "DriverOverhead"),
            toTableRow(this.details.timeBreakdown.driverOverheadTimes.interStageScheduling, "Inter-stage Scheduling", null, "DriverOverhead"),
            toTableRow(this.details.timeBreakdown.driverOverheadTimes.interTaskScheduling, "Inter-task Scheduling", null, "DriverOverhead"),
            toTableRow(this.details.timeBreakdown.io, "I/O"),
            toTableRow(this.details.timeBreakdown.persistentStorageIo, "Persistent Storage I/O"),
            toTableRow(this.details.timeBreakdown.remoteShuffleIo, "Remote Shuffle I/O"),
            toTableRow(this.details.timeBreakdown.localShuffleIo, "Local Shuffle I/O"),
        ]
    }

    ioBreakdownTable() {
        return [
            toTableRow(this.details.ioBreakdown.persistentStorageIo, "Persistent Storage I/O", "PersistentStorageIo"),
            toTableRow(this.details.ioBreakdown.persistentStorageIoDetails.bytesRead, "Bytes Read", null, "PersistentStorageIo"),
            toTableRow(this.details.ioBreakdown.persistentStorageIoDetails.bytesWritten, "Bytes Written", null, "PersistentStorageIo"),
            toTableRow(this.details.ioBreakdown.remoteShuffleIo, "Remote Shuffle I/O", "RemoteShuffleIo"),
            toTableRow(this.details.ioBreakdown.remoteShuffleIoDetails.bytesRead, "Bytes Read", null, "RemoteShuffleIo"),
            toTableRow(this.details.ioBreakdown.localShuffleIo, "Local Shuffle I/O", "LocalShuffleIo"),
            toTableRow(this.details.ioBreakdown.localShuffleIoDetails.bytesRead, "Bytes Read", null, "LocalShuffleIo"),
            toTableRow(this.details.ioBreakdown.localShuffleIoDetails.bytesWritten, "Bytes Written", null, "LocalShuffleIo"),
            toTableRow(this.details.ioBreakdown.spillIo, "Spill I/O", "SpillIo"),
            toTableRow(this.details.ioBreakdown.spillIoDetails.diskBytes, "Disk Bytes", null, "SpillIo"),
            toTableRow(this.details.ioBreakdown.spillIoDetails.memoryBytes, "Memory Bytes", null, "SpillIo"),
        ]
    }

    workloadStatsTable() {
        const numOfExecutors = { caption: "Number of Executors", unit: null, value: (this.details.workloadStats.totalNumOfCores.value || 0) / (this.details.workloadStats.numOfCoresPerExecutor.value || 1) };
        const numOfStages = { caption: "Number of Stages", unit: null, value: this.stages.length }
        return [
            toTableRow(this.details.workloadStats.numOfWorkerNodes, "Number of Worker Nodes"),
            toTableRow(this.details.workloadStats.totalNumOfCores, "Number of Cores"),
            toTableRow(numOfExecutors, "Number of Executors"),
            toTableRow(this.details.workloadStats.numOfCoresPerExecutor, "Number of Cores per Executor"),
            toTableRow(numOfStages, "Number of Stages"),
            toTableRow(this.details.workloadStats.totalNumberOfTasks, "Number of Tasks"),
            toTableRow(this.details.workloadStats.inputTableSize, "Input Tables Size"),
            toTableRow(this.details.workloadStats.totalNumberOfRecords, "Input Number of Rows"),
            toTableRow(this.details.workloadStats.partitionSize, "Partition Size"),
        ]
    }

    summaryComputeTable() {
        return [
            toTableRow(this.summary.time.total, "Total Time", "Total"),
            toTableRow(this.summary.time.compute, "Compute Time", null, "Total"),
            toTableRow(this.summary.time.concurrent, "Executor Busy Time", null, "Total"),
            toTableRow(this.summary.time.io, "I/O Time", null, "Total"),
            toTableRow(this.summary.time.overhead, "Overhead Time", null, "Total"),
        ]
    }

    summaryIoTable() {
        return [
            toTableRow(this.summary.io.inputRead, "Input Read"),
            toTableRow(this.summary.io.localShuffleRead, "Local Shuffle Read"),
            toTableRow(this.summary.io.remoteShuffleRead, "Remote Shuffle Read"),
            toTableRow(this.summary.io.outputWritten, "Output Written"),
            toTableRow(this.summary.io.shuffleWritten, "Shuffle Written"),
            toTableRow(this.summary.io.spill, "Spill"),
        ]
    }
}

export class Result implements SchemaResult {
    baseline: Analysis;
    compared: Analysis;
    speedup: number;
    scale: number;
    sameAsBaseline: boolean;
    withApu: boolean;
    comparedLog: boolean;
    flip: boolean;

    constructor(
        result: SchemaResult,
        params: Params,
        sameAsBaseline = true,
        withApu = true,
        comparedLog = false,
        flip = false
    ) {
        this.scale = Math.max(
            result.baseline.summary.time.total.value - result.baseline.summary.time.concurrent.value,
            result.compared.summary.time.total.value);
        this.baseline = new Analysis(result.baseline, Env.BASELINE, this);
        this.compared = new Analysis(result.compared, Env.COMPARED, this);
        this.speedup = result.speedup;

        this.sameAsBaseline = sameAsBaseline;
        this.withApu = withApu;
        this.comparedLog = comparedLog;
        this.flip = flip;
    }
}

export class Query implements SchemaQuery {
    end: number;
    filename: string;
    id: number;
    name: string;
    numStages: number;
    numTasks: number;
    result: (Result | null);
    sql: string;
    start: number;
    user: string;

    constructor(query: SchemaQuery) {
        this.end = query.end;
        this.filename = query.filename;
        this.id = query.id;
        this.name = query.name;
        this.numStages = query.numStages;
        this.numTasks = query.numTasks;
        this.result = query.result?.baseline ? new Result(query.result, { withApu: true, comparedLog: false }) : null;
        this.sql = query.sql;
        this.start = query.start;
        this.user = query.user;
    }
}

export class WaSchema implements SchemaWaSchema {
    queries: Query[];

    constructor(schemaWaSchema: SchemaWaSchema) {
        this.queries = schemaWaSchema.queries.map(query => new Query(query));
    }
}