import React, { useEffect, useState } from 'react'
import './App.css';
import { ClusterConfig, Compression } from './ClusterConfig';
import { Header } from './elements/Header/Header';
import { Results } from './Results';
import { Upload } from './Upload';
import { HowItWorks } from "./elements/HowItWorks/HowItWorks";
import { Result, WaSchema } from './query.ts';

export const Tabs = {
  CHOOSE: "choose",
  CONFIGURE: "configure",
  OVERVIEW: "overview",
  DETAILED: "detailed",
}

const demoFile = window.location.href.split("?benchmark=")[1];
const secrets = ["2147f721db86ec5dba9b580342550e6dfee183f71c565ff5c26520da55e9b40c", "af7a47daf17b048a07f79e9e08c1c1c00c4e27ce65baf50be2290121e61539ce"];
const CryptoJS = window.CryptoJS;
const py = window.py;
const MINI = window.location.href.startsWith("file://");
const QUERIES = (MINI && window.RESULT) ? new WaSchema(window.RESULT)
  .queries
  .filter((query) => (demoFile || window.location.host.startsWith("localhost:")) || !query.sql.includes("createOrReplaceTempView"))
  .reduce((acc, query) => ({
    ...acc, all: [...acc.all, {
      ...query,
      duration: query.result.baseline.summary.time.total.value - query.result.baseline.summary.time.concurrent.value,
      speedup: query.result?.speedup,
      apuDuration: (query.result.baseline.summary.time.total.value - query.result.baseline.summary.time.concurrent.value) / query.result?.speedup,
      concurrent: query.result.baseline.summary.time.concurrent.value,
    }]
  }), {
    selected: null,
    all: [],
  }) : null;
console.log(QUERIES);
const BASELINE_PARAMS = window.BASELINE_PARAMS ? { params: window.BASELINE_PARAMS } : null;
const COMPARED_PARAMS = window.COMPARED_PARAMS ? { params: { ...window.COMPARED_PARAMS, baselineWithApu: window.BASELINE_WITH_APU } } : null;

function App() {
  const [initialLoading, setInitialLoading] = useState(MINI ? null : "Loading...");
  const [loading, setLoading] = useState(MINI ? null : {
    text: initialLoading,
    clickThrough: false,
    done: false,
  });
  const [queries, setQueries] = useState(QUERIES);
  const [baselineLogFileName, setBaselineLogFileName] = useState("log1");
  const [baselineParams, setBaselineParams] = useState(BASELINE_PARAMS);
  const [comparedParams, setComparedParams] = useState(COMPARED_PARAMS);
  const [passwordCorrect, setPasswordCorrect] = useState(MINI || window.localStorage.getItem("passwordCorrect"));
  const [showIncorrectPassword, setShowIncorrectPassword] = useState(false);
  const [result, setResult] = useState(window.QUERY_ID !== undefined ? QUERIES.all[window.QUERY_ID].result : null);
  const [demoUploaded, setDemoUploaded] = useState(false);
  const [tab, setTab] = useState(MINI ? (window.QUERY_ID !== undefined ? Tabs.OVERVIEW : Tabs.CONFIGURE) : Tabs.CHOOSE);
  const [tabsEnabled, setTabsEnabled] = useState({
    [Tabs.CHOOSE]: true,
    [Tabs.CONFIGURE]: MINI || false,
    [Tabs.OVERVIEW]: window.QUERY_ID !== undefined || false,
    [Tabs.DETAILED]: window.QUERY_ID !== undefined || false,
  });
  const [modal, setModal] = useState(null);
  const [fileName, setFileName] = useState(null);

  const raiseError = (msg, critical = true) => setModal({
    content: <>
      <h1>ERROR</h1>
      {msg.split('\n').map(line => <p>{line}</p>)}
      {critical ?
        <button onClick={() => window.location.reload()}>OK</button> :
        <button onClick={() => setModal(null)}>OK</button>
      }
    </>
  });

  useEffect(() => {
    if (!baselineLogFileName) return;
    let params = {
      params: {
        radar_version: 1.2,
        storage: "hdfs",
        data_size: "10TB",
        logfile: baselineLogFileName,
        cluster_driver_num_of_vcpu: 8,
        cluster_worker_num_of_vcpu: 8,
        // cluster_num_of_nodes: 9,
        local_bandwidth_GBs: 3.125,
        input_bandwidth_GBs: 1.25,
        remote_bandwidth_GBs: 1.25,
        compression_type: Compression.UNCOMPRESSED,
        available_local_bandwidth_factor: 0.8,
        available_remote_bandwidth_factor: 0.8,
        available_storage_bandwidth_factor: 0.8,
        late_compute_time_fraction: 0,
        concurrent_io_usage_tasks_fraction: 1,
        parallel_compute_time: true,
        with_startup_time: true,
        cluster_worker_num_apus: 1,
      },
      plot: false
    };
    if (demoFile) {
      params.params.orig_cluster_worker_num_of_vcpu = 8;
      // params.params.orig_cluster_num_of_nodes = 9;
      params.params.orig_local_bandwidth_GBs = 6.25;
      params.params.orig_remote_bandwidth_GBs = 1.25;
      params.params.orig_input_bandwidth_GBs = 0.25;
      params.params.orig_compression_type = Compression.UNCOMPRESSED;
    }
    setBaselineParams(params)
    if (!comparedParams) setComparedParams({ ...params, baselineWithApu: true, apu: true });
  }, [baselineLogFileName]);


  useEffect(() => {
    if (queries?.result) {
      setResult(queries.result);
      setTabsEnabled({
        ...tabsEnabled,
        [Tabs.OVERVIEW]: true,
        [Tabs.DETAILED]: true,
      })
      setTab(Tabs.OVERVIEW);
      return;
    }
    if (!queries?.selected) return;
    const estimate = async () => {
      setLoading({
        text: "Analyzing Query. This might take a few minutes...",
        clickThrough: true,
        done: false,
      });
      setTabsEnabled({
        ...tabsEnabled,
        [Tabs.OVERVIEW]: false,
        [Tabs.DETAILED]: false,
      })
      let params = JSON.parse(JSON.stringify(baselineParams));
      params.params.query_id = parseInt(queries.selected, 10);
      let baselineQuery = demoFile ?
        await fetch("https://workloadanalyzer.speedata.io/benchresults/" + window.VERSION + "/" + demoFile).then(res => res.json()) :
        await py.call("get_apu_estimation", [params, {}], {
          parser_name: "speedup_apu_single_query", packer_name: "wa_schema"
        });
      if (baselineQuery.error) raiseError(baselineQuery.error.message);

      let result = new Result(baselineQuery, params, true, true, demoFile);

      // if (demoFile) {
      //   query.cpu = query.apu;
      //   query.stages.cpu = query.stages.apu;
      //   query.aggregate.cpu = query.aggregate.apu;
      // }

      if (!comparedParams.baselineWithApu) {
        let params = JSON.parse(JSON.stringify(comparedParams));
        params.params.query_id = params.params.query_id || parseInt(queries.selected, 10);

        let comparedQuery = comparedParams.apu ?
          await py.call("get_apu_estimation", [{
            ...params,
            params: {
              ...params.params,
              ...Object.entries(baselineParams.params).reduce((agg, [k, v]) => ({
                ...agg,
                ["orig_" + k]: demoFile ? {
                  local_bandwidth_GBs: 6.25,
                  remote_bandwidth_GBs: 1.25,
                  input_bandwidth_GBs: 0.25,
                  cluster_worker_num_of_vcpu: 8,
                  // cluster_num_of_nodes: 9,
                }[k] || v : v
              }), {})
            }
          }, {}], {
            parser_name: "speedup_apu_single_query", packer_name: "wa_schema"
          }) :
          await py.call("get_x86_estimation", [{
            ...params,
            params: {
              ...params.params,
              ...Object.entries(baselineParams.params).reduce((agg, [k, v]) => ({
                ...agg,
                ["orig_" + k]: demoFile ? {
                  local_bandwidth_GBs: 6.25,
                  remote_bandwidth_GBs: 1.25,
                  input_bandwidth_GBs: 0.25,
                  cluster_worker_num_of_vcpu: 8,
                  // cluster_num_of_nodes: 9,
                }[k] || v : v
              }), {})
            }
          }], {
            model_name: "ct_reschedule_x86", packer_name: "wa_schema"
          });
        if (comparedQuery.error) raiseError(comparedQuery.error.message);
        let comparedResult = new Result(comparedQuery, params, false, comparedParams.apu, demoFile);
        result.compared = comparedResult.compared;
        result.speedup = comparedResult.speedup;
      }
      setResult(result);

      setLoading({
        ...loading,
        clickThrough: true,
        done: true,
      });
      setTabsEnabled({
        ...tabsEnabled,
        [Tabs.OVERVIEW]: true,
        [Tabs.DETAILED]: true,
      })
      setTab(Tabs.OVERVIEW)
    }
    estimate();
  }, [queries, baselineParams, comparedParams])

  const validatePassword = () => {
    let hash = CryptoJS.SHA256(document.getElementById("password").value).toString();
    if (secrets.includes(hash)) {
      window.localStorage.setItem("passwordCorrect", true);
      setPasswordCorrect(true);
      setModal(null);
    } else setShowIncorrectPassword(true);
  }

  if (!MINI && initialLoading) {
    let interval = setInterval(() => {
      if (py.ready) {
        clearInterval(interval);
        setLoading(null);
        setInitialLoading(null);
      }
    }, 100);
  }

  useEffect(() => {
    if (!passwordCorrect) setModal({
      content: <div>
        <div className="sign-in" onKeyDown={(e) => { setShowIncorrectPassword(false); e.key === "Enter" && validatePassword() }}>
          <h1>Sign in to Workload Analyzer</h1>
          <input id="password" type="password" placeholder="Access Code" />
          <div style={{ opacity: (showIncorrectPassword ? 1 : 0), color: "#cc3333" }}>Incorrect Password</div>
          <button onClick={() => validatePassword()}>Sign In</button>
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    });
  }, [passwordCorrect, showIncorrectPassword])

  return <>
    <Header queries={queries} setQueries={setQueries} setTab={setTab} tab={tab} tabsEnabled={tabsEnabled} loggedIn={passwordCorrect} />
    {loading ? <div className="loading" style={{ opacity: passwordCorrect ? 1 : 0 }}>
      {loading.clickThrough && <HowItWorks loading={loading} setLoading={setLoading} setTab={setTab} />}
      {loading.done || <div>
        {loading.text}
        <div className="improvement">
          <div className="bg"></div>
        </div>
        {loading.clickThrough && <button onClick={() => window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/'}>Start Over</button>}
      </div>}
    </div> : (
      !passwordCorrect ? <>
      </> : (
        <div className="content" onClick={e => setModal(null)}>
          {tab === Tabs.CHOOSE && <Upload setQueries={setQueries} setLoading={setLoading} setBaselineLogFileName={setBaselineLogFileName} setDemoUploaded={setDemoUploaded} setTab={setTab} setTabsEnabled={setTabsEnabled} tabsEnabled={tabsEnabled} queries={queries} demoUploaded={demoUploaded} raiseError={raiseError} setFileName={setFileName} />}
          {tab === Tabs.CONFIGURE && <ClusterConfig
            setModal={setModal}
            baselineParams={baselineParams}
            setBaseParams={setBaselineParams}
            queries={queries}
            comparedParams={comparedParams}
            setComparedParams={setComparedParams}
            demoFile={demoFile}
            raiseError={raiseError}
            setQueries={setQueries}
            fileName={fileName}
          />}
          {[Tabs.OVERVIEW, Tabs.DETAILED].includes(tab) && <Results tab={tab} setTab={setTab} setModal={setModal} result={result} />}

        </div>
      )
    )}
    {modal && <div className="modal">
      <div className="bg" onClick={e => setModal(null)} />
      <div className="modal-content">{modal.content}</div>
    </div>}
  </>
}

export default App;
