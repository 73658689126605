import React from 'react'
import './HowItWorks.css'
import { Tabs } from '../../App';

// const animation = [
//     [100, '.bar.query']
// ]

export function HowItWorks({ loading, setLoading, setTab }) {
    return (
        <div className="how-it-works">
            <h1>How the Speedata APU accelerates your workloads</h1>
            <div className="wrapper">
                <div className="illustration">
                    <div className="bar query" style={{ width: 900 }}>Query</div>

                    <div className="bar stage n-1" style={{ left: 0, width: 300 }}>Stage</div>
                    <div className="bar stage n-2" style={{ left: 300, width: 400 }}>Stage</div>
                    <div className="bar stage n-3" style={{ left: 700, width: 200 }}>Stage</div>

                    <div className="bar task n-1" style={{ left: 0, width: 100 }}>Task</div>
                    <div className="bar task n-2" style={{ left: 100, width: 100 }}>Task</div>
                    <div className="bar task n-3" style={{ left: 200, width: 100 }}>Task</div>
                    <div className="bar task n-4" style={{ left: 300, width: 100 }}>Task</div>
                    <div className="bar task n-5" style={{ left: 400, width: 100 }}>Task</div>
                    <div className="bar task n-6" style={{ left: 500, width: 100 }}>Task</div>
                    <div className="bar task n-7" style={{ left: 600, width: 100 }}>Task</div>
                    <div className="bar task n-8" style={{ left: 700, width: 100 }}>Task</div>
                    <div className="bar task n-9" style={{ left: 800, width: 100 }}>Task</div>
                </div>
            </div>
            <div style={{ marginTop: "170px" }}>
                <h2 className="line l-1">Your query is comprised of stages</h2>
                <h2 className="line l-2">Each stage is made of tasks</h2>
                <h2 className="line l-3">The Speedata APU accelerates the computation and I/O of each task</h2>
                <h2 className="line l-4">Which makes the total execution time of the query significantly shorter</h2>
            </div>

            {loading.done && <button onClick={() => { setLoading(null); setTab(Tabs.OVERVIEW) }}>Show me the Results</button>}
        </div>
    )
}
