import './Upload.css'
import { Tabs } from './App';
import { WaSchema } from './query.ts';
const py = window.py;

const demoFile = window.location.href.split("?benchmark=")[1];

export function Upload({ setQueries, setLoading, setBaselineLogFileName, setDemoUploaded, setTab, setTabsEnabled, tabsEnabled, queries, demoUploaded, raiseError, setFileName }) {

    const upload = async (fname, data) => {
        if (window.uploaded) return;
        window.uploaded = true;
        console.log('transferring to python....', fname)
        let queries;
        try {
            let rawJson = JSON.parse(new TextDecoder().decode(data));
            queries = new WaSchema(rawJson).queries.filter(query => query.result?.baseline);
        } catch (e) {
            console.log('could not parse: ', e);
            const logFileName = (await py.upload(fname, data, "log1")).logFileName;
            const response = await py.call("get_queries_list", [{ spark_log: logFileName }]);
            if (response.error) raiseError(queries.error.message);
            setBaselineLogFileName(logFileName);
            queries = response.queries;
        }
        const foundQueries = queries
            .filter((query) => (demoFile || window.location.host.startsWith("localhost:")) || !query.sql.includes("createOrReplaceTempView"))
            .slice(demoFile ? -1 : 0)
            .reduce((acc, query) => ({ ...acc, all: [...acc.all, { ...query, duration: query.end - query.start }] }), {
                selected: null,
                all: [],
            });
        if (foundQueries.all.length === 0) return raiseError("No queries found in the log file.");
        setQueries(foundQueries)
        setTab(Tabs.CONFIGURE);
        setTabsEnabled({
            ...tabsEnabled,
            [Tabs.CONFIGURE]: true,
        })
        setLoading(null);
    }

    const handleFileSelection = (e) => {
        const fileList = e.target.files;
        const fname = fileList[0].name || "log1";
        setLoading({
            text: "Analyzing your Spark log...",
            clickThrough: false,
            done: false,
        });
        const reader = new FileReader();
        reader.addEventListener('load', async (event) => {
            await upload(fname, event.target.result);
        });
        setFileName(fname);
        reader.readAsArrayBuffer(fileList[0]);
    }

    if (demoFile && !demoUploaded) {
        console.log(demoFile);
        setDemoUploaded(true);
        setLoading({
            text: "Analyzing your Spark log...",
            clickThrough: false,
            done: false,
        });
        fetch("https://workloadanalyzer.speedata.io/logs/" + demoFile + ".zip")
            .then(async (response) => {
                const data = await response.arrayBuffer();
                await upload(demoFile + ".zip", data)
            });
        return;
    }

    return (
        <div className="upload">
            <div>
                <h1>Workload Analyzer</h1>
                <br />
                <h2>Use this tool to analyze your Spark logs,
                    <br />and learn what you can do to speed up your queries.</h2>
            </div>
            <div>
                <input type="file" id="file" style={{ display: "none" }} onChange={handleFileSelection} />
                {queries === null ? <div className="demo-files">
                    <button onClick={() => document.getElementById("file").click()}>Choose a File from Your Computer</button>
                    or <select onChange={(e) => e.target.value && (window.location.href = "?benchmark=" + e.target.value)}>
                        <option value="">Choose a Benchmark File</option>
                        <option value="query_3">TPC-DS Query 3</option>
                        <option value="query_7">TPC-DS Query 7</option>
                        <option value="query_8">TPC-DS Query 8</option>
                        <option value="query_9">TPC-DS Query 9</option>
                        <option value="query_10">TPC-DS Query 10</option>
                        <option value="query_12">TPC-DS Query 12</option>
                        <option value="query_13">TPC-DS Query 13</option>
                        <option value="query_15">TPC-DS Query 15</option>
                        <option value="query_18">TPC-DS Query 18</option>
                        <option value="query_20">TPC-DS Query 20</option>
                        <option value="query_21">TPC-DS Query 21</option>
                        <option value="query_22">TPC-DS Query 22</option>
                        <option value="query_25">TPC-DS Query 25</option>
                        <option value="query_26">TPC-DS Query 26</option>
                        <option value="query_27">TPC-DS Query 27</option>
                        <option value="query_28">TPC-DS Query 28</option>
                        <option value="query_29">TPC-DS Query 29</option>
                        <option value="query_31">TPC-DS Query 31</option>
                        <option value="query_33">TPC-DS Query 33</option>
                        <option value="query_34">TPC-DS Query 34</option>
                        <option value="query_36">TPC-DS Query 36</option>
                        <option value="query_37">TPC-DS Query 37</option>
                        <option value="query_38">TPC-DS Query 38</option>
                        <option value="query_40">TPC-DS Query 40</option>
                        <option value="query_42">TPC-DS Query 42</option>
                        <option value="query_43">TPC-DS Query 43</option>
                        <option value="query_44">TPC-DS Query 44</option>
                        <option value="query_47">TPC-DS Query 47</option>
                        <option value="query_48">TPC-DS Query 48</option>
                        <option value="query_50">TPC-DS Query 50</option>
                        <option value="query_52">TPC-DS Query 52</option>
                        <option value="query_53">TPC-DS Query 53</option>
                        <option value="query_55">TPC-DS Query 55</option>
                        <option value="query_56">TPC-DS Query 56</option>
                        <option value="query_57">TPC-DS Query 57</option>
                        <option value="query_58">TPC-DS Query 58</option>
                        <option value="query_60">TPC-DS Query 60</option>
                        <option value="query_62">TPC-DS Query 62</option>
                        <option value="query_63">TPC-DS Query 63</option>
                        <option value="query_71">TPC-DS Query 71</option>
                        <option value="query_73">TPC-DS Query 73</option>
                        <option value="query_75">TPC-DS Query 75</option>
                        <option value="query_79">TPC-DS Query 79</option>
                        <option value="query_82">TPC-DS Query 82</option>
                        <option value="query_83">TPC-DS Query 83</option>
                        <option value="query_84">TPC-DS Query 84</option>
                        <option value="query_87">TPC-DS Query 87</option>
                        <option value="query_88">TPC-DS Query 88</option>
                        <option value="query_89">TPC-DS Query 89</option>
                        <option value="query_90">TPC-DS Query 90</option>
                        <option value="query_91">TPC-DS Query 91</option>
                        <option value="query_93">TPC-DS Query 93</option>
                        <option value="query_95">TPC-DS Query 95</option>
                        <option value="query_96">TPC-DS Query 96</option>
                        <option value="query_98">TPC-DS Query 98</option>
                    </select>
                </div> : <div className="demo-files">
                    A log file has been selected. <button onClick={() => window.location.href = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/'}>Analyze a Different File</button>
                </div>}
            </div>
            <div className="disclaimer">
                <img src="safe.svg" style={{ width: "80px" }} alt="Safe and Secure" />
                <div className="text">
                    <b>Speedata Workload Analyzer works completely in your browser.</b><br />
                    The files you analyze are not uploaded to the Speedata servers or anywhere else,<br />
                    and aren't used by us in any way. For more information, see our <a href="https://www.speedata.io/privacy-policy">Privacy Policy</a>.
                </div>
            </div>
        </div>
    )
}
