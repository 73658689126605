import { msToHumanReadable } from '../../utils'
import { StackedBars } from '../StackedBars/StackedBars'
import { MetricsTable } from '../Detailed/MetricsTable';
import { Donut } from '../Donut/Donut';
import React from 'react'

export function Summary({
    result,
    setModal,
    comparedCaption,
    axis,
}) {
    const CONCURRENT_LEARN_MORE = <>
        <h2>Concurrent Queries Detected</h2>
        <br />
        Some tasks from other queries within this application overlap with this query.<br /><br />
        The "EXECUTOR BUSY" metric below captures the total time that the executors were busy running tasks from other queries within the duration of this query.<br /><br />
        We cannot accurately estimate the impact of the APU in such scenarion.<br /><br />
        Instead, we provide a best-effort estimation of the execution time on the APU, assuming the query runs in isolation. We also estimate this query duration on the CPU if it ran in isolation, and use that time for comparison.<br /><br />
        For best prediction estimation, consider re-running this query in isolation and run Workload Analyzer on that execution.<br /><br />
        <button onClick={() => setModal(null)}>Ok</button>
    </>;

    console.log("result", result);
    let speedupFactor = (result.baseline.summary.time.total.value - result.baseline.summary.time.concurrent.value) / result.compared.summary.time.total.value;
    // speedupFactor = speedupFactor > 1 ? speedupFactor : 1 - speedupFactor;
    // const speedUpOrSlowDown = speedupFactor > 1 ? "speed up" : "slow down";
    const apuTitle = result.withApu ? "APU and " : "";
    const comparedCluster = result.comparedLog ? "second environment" : "cluster changes";
    const title = result.sameAsBaseline && result.withApu ?
        "The APU speeds up your query by " :
        "The " + apuTitle + comparedCluster + " " + "speed up your query by ";

    const baseline = {
        scale: result.scale,
        classNames: [],
        data: [
            { value: result.baseline.summary.time.total.value - result.baseline.summary.time.concurrent.value, color: "#c42930" },
        ],
    }

    const compared = {
        scale: result.scale,
        classNames: [],
        data: [
            { value: result.compared.scale, color: result.withApu ? "#133349" : "#c42930" },
        ],
    }

    return <>
        <h1 className="title">{title}<span className="speedup">{speedupFactor.toFixed(1)}x</span><br />
            from <span className={speedupFactor > 1 ? "bad-time" : "good-time"} > {msToHumanReadable(result.baseline.summary.time.total.value - result.baseline.summary.time.concurrent.value)} </span>
            {result.baseline.summary.time.concurrent.value > 0 && "(estimated) "}
            to
            <span className={speedupFactor > 1 ? "good-time" : "bad-time"}> {msToHumanReadable(result.compared.summary.time.total.value)}</span>
            {result.baseline.summary.time.concurrent.value > 0 && <span style={{ fontSize: ".8em" }}>
                <br />
                Note: Detected concurrent queries within this spark application (<a href="javascript://" onClick={(e) => { e.stopPropagation(); setModal({ content: CONCURRENT_LEARN_MORE }) }}>learn more</a>)<br />
                Original execution time was {msToHumanReadable(result.baseline.summary.time.total.value)}
            </span>}
        </h1 >

        <div className="lane-caption" style={{ paddingTop: "12px" }}>
            Baseline
        </div>
        <div className="bars">
            <StackedBars bars={baseline} />
        </div>
        <div className="lane-caption" style={{ marginTop: "5px" }}>
            {comparedCaption}
        </div>
        <div className="bars" style={{ marginTop: "-5px" }}>
            <StackedBars bars={compared} />
            <StackedBars bars={{ ...axis, altScale: result.compared.scale }} />
            <br />
            <br />
            <div style={{ textAlign: "center" }}><b>Query Execution Time</b></div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="lane-caption"></div>
        <div className="bars">
            <Donut caption="Baseline" apu={false} compute={result.baseline.summary.time.compute.value / result.baseline.scale} io={result.baseline.summary.time.io.value / result.baseline.scale} />
            <Donut caption={comparedCaption} apu={true} compute={result.compared.summary.time.compute.value / result.compared.scale} io={result.compared.summary.time.io.value / result.compared.scale} />
        </div>
        <br />
        <div className="legend">
            <br />
            <div className="legend-color" style={{ backgroundColor: "#cad6dc" }}>&nbsp;</div>
            <div className="legend-text">Overhead</div>
            <div className="legend-color" style={{ backgroundColor: "#9bb0bb" }}>&nbsp;</div>
            <div className="legend-text">I/O</div>
            <div className="legend-color" style={{ backgroundColor: "#c42930" }}>&nbsp;</div>
            <div className="legend-text">Compute with CPU Only</div>
            {result.withApu && <>
                <div className="legend-color" style={{ backgroundColor: "#133349" }}>&nbsp;</div>
                <div className="legend-text">Compute with Speedata APU</div>
            </>}
        </div>
        <br />
        <br />
        <h2>Time Breakdown Analysis</h2>
        <MetricsTable
            rows={result.baseline.summaryComputeTable()}
            comparedRows={result.compared.summaryComputeTable()}
            caption="Baseline"
            comparedCaption={comparedCaption}
            setModal={setModal}
            expandAll={true}
        />
        <h2>I/O Breakdown Analysis</h2>
        <MetricsTable
            rows={result.baseline.summaryIoTable()}
            comparedRows={result.compared.summaryIoTable()}
            caption="Baseline"
            comparedCaption={comparedCaption}
            setModal={setModal}
        />
        <h2>System & Workload Stats</h2>
        <MetricsTable
            rows={result.baseline.workloadStatsTable()}
            comparedRows={result.compared.workloadStatsTable()}
            caption="Baseline"
            comparedCaption={comparedCaption}
            setModal={setModal}
        />

    </>
}
