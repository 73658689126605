import React from 'react'
import './StackedBars2.css';

const MIN_BAR_WIDTH = 5;

export function StackedBars2({ bars }) {
    const scale = bars.scale;
    const data = bars.data.filter(d => d.type !== "gap" && d.value > 0).reduce((data, d) => ({
        bars: [...data.bars, {
            ...d,
            pct: Math.max(d.value / scale * 100.0, d.placeholder ? 0 : MIN_BAR_WIDTH),
        }],
        snip: data.snip + ((d.value / scale * 100.0) < MIN_BAR_WIDTH ? MIN_BAR_WIDTH - d.value / scale * 100.0 : 0),
        large: data.large + ((d.value / scale * 100.0 > MIN_BAR_WIDTH) ? 1 : 0),
    }), { bars: [], snip: 0, large: 0 });

    const largest = data.bars.reduce(([max, largest], bar, ix) => {
        if (ix < 1) return [max, largest];
        if (bar.pct > max) return [bar.pct, ix];
        return [max, largest];
    }, [0, 2])[1];

    if (!data.bars.filter(b => !b.placeholder).length) return null;

    return (
        <div className="stacked-bars-2">
            {data.bars.map((bar, ix) =>
                <div key={ix}
                    className="bar tooltip"
                    style={{
                        width: "calc(" + Math.max(bar.placeholder ? 0 : 5, Math.min(95, ix === largest ? bar.pct - data.snip : bar.pct)) + "% - 10px)",
                        margin: (bar.placeholder && ix > 0) ? 0 : "5px",
                        backgroundColor: bar.color,
                        opacity: bar.placeholder ? 0 : 1,
                        color: bar.font ? bar.font : "#fff",
                    }}
                    title={bar.tooltip}>
                    <span className="tooltiptext" style={{ backgroundColor: bar.color, "--tooltip-color": bar.color }}>{bar.caption}</span>
                    <div className="bar-caption">{bar.caption}</div>
                </div>
            )}
        </div>
    )
}
