import React, { useState } from 'react'
import { msToHumanReadable } from '../../utils'
import { StackedBars } from '../StackedBars/StackedBars'
import { MetricsTable } from './MetricsTable';
import { Strings } from 'waschema';

export function QueryBreakdown({
    result,
    caption,
    comparedCaption,
    axis,
    comparedAxis,
    setModal,
    gotoStage,
}) {
    const [alt, setAlt] = useState(false);
    const baselineAvgStage = result.baseline.stages.reduce((acc, stage) => acc + stage.end - stage.start, 0) / result.baseline.stages.length;
    const comparedAvgStage = result.compared.stages.reduce((acc, stage) => acc + stage.end - stage.start, 0) / result.compared.stages.length;

    setTimeout(() => {
        Array.from(document.querySelectorAll('.has-hidden-stages')).forEach(el => el.remove());
        Array.from(document.querySelectorAll('.hidden-stages-caption')).forEach(el => el.remove());
        const width = document.querySelector('.bars.compared .tick.floating .timestamp.scale-marker.alt')
            .parentElement
            .style
            .left;
        const lanes = Array.from(document.querySelectorAll('.bars.compared .stacked-bars:not(.axis)'))
            .filter(el => el.querySelector('.caption') === null);
        lanes.forEach((el, ix) => {
            const overlay = document.createElement('div');
            overlay.className = "has-hidden-stages";
            overlay.style.width = width;
            el.appendChild(overlay);

            if (ix > 0) return;

            const caption = document.createElement('div');
            caption.className = "hidden-stages-caption";
            caption.style.left = width;
            caption.innerHTML = '<i>This area contains<br/>stages that aren\'t<br/>visible at this scale</i>';

            const handleWidth = parseFloat(width.split('px')[0]) / 2 + 10;
            const handle = document.createElement('div');
            handle.className = "hidden-stages-caption-handle";
            handle.innerHTML = "&nbsp;"
            handle.style.width = handleWidth + "px";
            handle.style.left = (-handleWidth) + "px";
            caption.appendChild(handle);
            el.append(caption);
        });
    }, 100)

    const showNotAccelerated = (e, extent, reasons) => {
        e.stopPropagation();
        setModal({
            content: <div>
                <h1>Why is this stage {extent} accelerated?</h1>
                {reasons.map((item, ix) => <div key={ix}>
                    <h3>{Strings[item.reason]}:</h3>
                    <ul style={{ marginLeft: "20px" }}>
                        {item.details.map((detail, ix) => <li key={ix}>{detail}</li>)}
                    </ul>
                    <br />
                </div>)}
                <button onClick={() => setModal(null)}>Ok</button>
            </div>
        })
    }

    return <>
        <h2>Query Breakdown into Stages *</h2>
        <div className="lane-caption" style={{ paddingTop: "10px" }}>{caption}</div>
        <div className="bars baseline">
            <br />
            <div style={{ overflow: "visible" }}> {result.baseline.componentChart("").map((bars, ix) => (
                <StackedBars key={ix} className={ix % 2 ? "" : "alt-lane"} bars={{ ...bars, altScale: result.compared.scale, alt, setAlt }} height={60} onBarClick={gotoStage} />
            ))} </div>
        </div>
        <div className="lane-caption" style={{ marginTop: "-5px" }}></div>
        <div className="bars" style={{ margin: "-5px 0" }}>
            <div style={{ borderLeft: "1px solid #9bb0bb", fontSize: "16px", padding: "20px 20px 20px 20px" }}>
                On average, <span style={{ color: "rgb(204, 51, 51)", fontWeight: 800 }}>a CPU stage taking {msToHumanReadable(baselineAvgStage)}</span> was accelerated by <b>{(baselineAvgStage / comparedAvgStage).toFixed(1)}x</b> <span style={{ color: "rgb(19, 51, 73)", fontWeight: 800 }}>an APU stage taking {msToHumanReadable(comparedAvgStage)}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;<button onClick={() => setAlt(!alt)} style={{ backgroundColor: "rgb(204, 51, 51)", padding: "5px", border: "1px solid #ccc", borderRadius: "15px", color: "#fff", fontWeight: 600 }}>Show me</button>
            </div>
        </div>
        <div className="lane-caption">{comparedCaption}</div>
        <div className="bars compared" style={{ marginTop: "-15px", position: "relative" }}>
            <br />
            <div className="white-caption"> {result.compared.componentChart("").map((bars, ix) => (
                <StackedBars key={ix} className={ix % 2 ? "" : "alt-lane"} bars={{ ...bars, altScale: result.compared.scale, scale: result.baseline.scale, alt, setAlt }} onBarClick={gotoStage} />
            ))} </div>
            <StackedBars className={"boop good"} bars={{ ...result.baseline.axis(result.compared.scale), alt, setAlt }} />
            <br />
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h2>Stage Timeline</h2>

        <table className="stats">
            <thead>
                <tr>
                    <th>Stage</th>
                    <th>{caption}</th>
                    <th>{comparedCaption}</th>
                    <th>Hardware Accelerated&nbsp;
                        <span className="info tooltip" style={{ marginTop: "-5px" }}>?<span className="tooltiptext" style={{ width: "400px", marginLeft: "-210px", padding: "10px" }}>
                            Full acceleration – entire stage runs on APU<br /><br />Partial acceleration – while stage is APU accelerated, some computations run on CPU
                        </span>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                {result.baseline.stages.map((stage, ix) => <tr
                    className={ix % 2 ? "" : "odd"}
                    key={ix}>
                    <td>
                        Stage {stage.num}
                    </td>
                    <td>
                        {msToHumanReadable(stage.end - stage.start, true)}
                        {stage.breakdown.concurrent.value > 0 && <span className="tooltip">
                            &nbsp;*
                            <span className="tooltiptext" style={{ width: "400px", marginLeft: "-200px" }}>
                                There were concurrent tasks from other queries within this application (this Spark Job) during the execution of this stage.
                            </span>
                        </span>}
                    </td>
                    <td>
                        {msToHumanReadable(result.compared.stages[ix].end - result.compared.stages[ix].start, true)}
                    </td>
                    <td>
                        {stage.notSupported() ? <>No (<a href="javascript://" onClick={(e) => showNotAccelerated(e, "not", stage.notAccelerated)}>learn why</a>)</> : (
                            stage.partiallySupported() ? <>Partial (<a href="javascript://" onClick={(e) => showNotAccelerated(e, "partially", stage.notAccelerated)}>learn why</a>)</> : "Full")}
                    </td>
                </tr>)}
            </tbody>
        </table >

        <br />
        <br />
        <br />
        <h2>Time Breakdown Analysis</h2>
        <MetricsTable
            rows={result.baseline.timeBreakdownTable()}
            comparedRows={result.compared.timeBreakdownTable()}
            caption={caption}
            comparedCaption={comparedCaption}
            setModal={setModal}
        />

        <br />
        <br />
        <br />
        <h2>I/O Breakdown Analysis</h2>
        <MetricsTable
            rows={result.baseline.ioBreakdownTable()}
            comparedRows={result.compared.ioBreakdownTable()}
            caption={caption}
            comparedCaption={comparedCaption}
            setModal={setModal}
        />

        <br />
        <br />
        <br />
    </>
}
