import React from 'react'
import './Header.css';
import { Tabs } from '../../App';

const MINI = window.location.href.startsWith("file://");

export function Header({ setTab, tab, tabsEnabled, loggedIn }) {
    return (<>
        <div className="header">
            <a href={window.INDEX_FNAME ? window.INDEX_FNAME + (window.INDEX_FNAME.startsWith("../") ? "" : ".html") : "/"}><div className="logo-container">
                Workload Analyzer
            </div>
            </a>
        </div>
        {loggedIn && <div className="sidebar">
            {!MINI && <>
                <div onClick={() => tabsEnabled[Tabs.CHOOSE] && setTab(Tabs.CHOOSE)} className={"nav-item" + (tabsEnabled[Tabs.CHOOSE] ? " enabled" : "") + (tab === Tabs.CHOOSE ? " selected" : "")}>
                    <div className="nav-icon choose-file"></div>
                    Choose a File
                </div>
            </>}
            <div onClick={() => (MINI && tab !== Tabs.CONFIGURE) ? (window.location.href = window.location.href.substring(0, window.location.href.lastIndexOf('/')) + '.html') : tabsEnabled[Tabs.CONFIGURE] && setTab(Tabs.CONFIGURE)} className={"nav-item" + (tabsEnabled[Tabs.CONFIGURE] ? " enabled" : "") + (tab === Tabs.CONFIGURE ? " selected" : "")}>
                <div className="nav-icon configure"></div>
                {MINI ? "Results Overview" : "Configure"}
            </div>
            <div onClick={() => tabsEnabled[Tabs.OVERVIEW] && setTab(Tabs.OVERVIEW)} className={"nav-item" + (tabsEnabled[Tabs.OVERVIEW] ? " enabled" : "") + (tab === Tabs.OVERVIEW ? " selected" : "")}>
                <div className="nav-icon overview"></div>
                {MINI ? "Query Overview" : "Result Overview"}
            </div>
            <div onClick={() => tabsEnabled[Tabs.DETAILED] && setTab(Tabs.DETAILED)} className={"nav-item" + (tabsEnabled[Tabs.DETAILED] ? " enabled" : "") + (tab === Tabs.DETAILED ? " selected" : "")}>
                <div className="nav-icon detailed-analysis"></div>
                {MINI ? "Query Details" : "Detailed Analysis"}
            </div>
            <div className="footer">
                Workload Analyzer {window.VERSION}. <a href="javascript://">(what's new?)</a><br />
                Copyright &copy; 2024, <a href="https://speedata.io">Speedata</a>.<br />
                All rights reserved.
            </div>
        </div>}
    </>)
}
