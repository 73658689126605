export async function getTgz(file) {
    let response = await fetch(file + ".tar.gz?" + new Date().valueOf());
    let buffer = await response.arrayBuffer();
    await window.py.unpackArchive(buffer, "tar.gz");
};

export function p(str) {
    return JSON.parse(window.py.runPython("dumps(" + str + ")"));
}

export function secondsToHumanReadable(seconds, short) {
    let sStr = short ? ["s", "s"] : [" seconds", " second"];
    let mStr = short ? ["m", "m"] : [" minutes and ", " minute and "];
    let hStr = short ? ["h", "h"] : [" hours, ", " hour, "];

    if (seconds === 0) return "0";

    if (seconds < 0.1) {
        const ms = parseInt(seconds * 1000);
        if (ms === 0) return "<1ms";
        return ms + "ms"
    };

    sStr = seconds === 1 ? sStr[1] : sStr[0];

    if (parseFloat(seconds) < 60) {
        return `${seconds.toFixed(1)}${sStr}`;
    }
    seconds = parseInt(seconds, 10);

    const minutes = parseInt(seconds / 60, 10);
    mStr = minutes === 1 ? mStr[1] : mStr[0];
    if (minutes < 60) {
        return `${minutes}${mStr}${seconds % 60}${sStr}`;
    }

    const hours = parseInt(minutes / 60, 10);
    hStr = hours === 1 ? hStr[1] : hStr[0];
    return `${hours}${hStr}${minutes % 60}${mStr}${seconds % 60}${sStr}`;
}

export const secondsToString = (seconds) => {
    return seconds.toFixed(2) + "s";
}

export function msToHumanReadable(ms, short) {
    return secondsToHumanReadable(ms / 1000, short);
}

export const bytesToString = (bytes) => {
    if (bytes === 0) return "0";
    if (isNaN(bytes)) {
        return NaN;
    }
    const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const index = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, index)).toFixed(2) + " " + units[index];
}

export const showDifference = () => {
    setTimeout(() => {
        document.querySelectorAll(".comparison").forEach(comparison => {
            const comparisonRect = comparison.getBoundingClientRect();
            const boops = Array.from(comparison.querySelectorAll(".boop")).map(el => el.getBoundingClientRect())
            const left = boops[0].width + boops[0].left - comparisonRect.left + 30;
            const top = boops[0].top - comparisonRect.top + boops[0].height / 2 + 5;
            const height = boops[1].top - boops[0].top;
            const difference = comparison.querySelector(".difference");
            difference.style.height = `${height}px`;
            difference.style.left = `${left}px`;
            difference.style.top = `${top}px`;
            const differenceInner = comparison.querySelector(".difference-inner");
            differenceInner.style.width = `${height}px`;
        });
    });
}