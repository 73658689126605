import React from 'react';
import './Donut.css';

const anchorStyle = (pct, pctOffset = 0) => {
    const offsetTop = 20;
    const offsetLeft = 32;
    const radius = 120;
    return {
        left: offsetLeft + radius * (1 + Math.sin(Math.PI * (pctOffset * 2 + pct)) * .75),
        top: offsetTop + radius * (1 - Math.cos(Math.PI * (pctOffset * 2 + pct)) * .75)
    }
}

export function Donut({ caption, apu, compute, io }) {
    const overhead = 1 - compute - io;
    const donutStyle = {
        background: `conic-gradient(
            #${apu ? "133349" : "c42930"} 0deg ${compute * 360 - .25}deg,
            #9bb0bb ${compute * 360 + .25}deg ${(compute + io) * 360 - .25}deg,
            #cad6dc ${(compute + io) * 360 + .25}deg 359.5deg
        )`,
    }

    const computeAnchor = anchorStyle(compute);
    const ioAnchor = anchorStyle(io, compute);
    const overheadAnchor = anchorStyle(overhead, compute + io);

    const diffA = Math.abs(computeAnchor.top - ioAnchor.top);
    if (diffA < 35) (computeAnchor.top > ioAnchor.top) ? ioAnchor.top -= 35 - diffA : computeAnchor.top -= 35 - diffA;

    const diffB = Math.abs(overheadAnchor.top - ioAnchor.top);
    if (diffB < 35) (overheadAnchor.top > ioAnchor.top) ? ioAnchor.top -= 35 - diffB : overheadAnchor.top -= 35 - diffB;

    const diffC = Math.abs(overheadAnchor.top - computeAnchor.top);
    if (diffC < 35) (overheadAnchor.top > computeAnchor.top) ? computeAnchor.top -= 35 - diffC : overheadAnchor.top -= 35 - diffC;

    return <div className="donut-wrapper">
        <div className="donut" style={donutStyle}>
            <div className="hole"></div>
            <div className="caption">{caption}</div>
        </div>
        <div className="marker-anchor" style={computeAnchor}>Compute {(compute * 100).toFixed(2)}%</div>
        <div className="marker-anchor" style={ioAnchor}>IO {(io * 100).toFixed(2)}%</div>
        <div className="marker-anchor" style={overheadAnchor}>Overhead {(overhead * 100).toFixed(2)}%</div>
    </div>
}
