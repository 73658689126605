import React, { useState } from 'react'
import "./Results.css"
import { Summary } from "./elements/Summary/Summary";
import { QueryBreakdown } from "./elements/Detailed/QueryBreakdown";
import { StageBreakdown } from './elements/Detailed/StageBreakdown';
import { TaskBreakdown } from './elements/Detailed/TaskBreakdown';
import { Tabs } from './App';

const DetailedTabs = {
    QUERY: "query",
    STAGE: "stage",
    TASK: "task",
}

export const TaskTabs = {
    BASELINE: "baseline",
    COMPARED: "compared",
}

export function Results({ tab, setModal, result }) {
    // const stages = Object.entries(baselineQuery.stages.cpu).filter(([k, _]) => !["lanes", "stageBars"].includes(k)).sort((a, b) => a[1].start - b[1].start);
    // const comparedStages = Object.entries(comparedQuery.stages.apu).filter(([k, _]) => !["lanes", "stageBars"].includes(k)).sort((a, b) => a[1].start - b[1].start);
    const [stageNum, setStageNum] = useState(result.baseline.stages[0].num);
    const [task, setTask] = useState({
        tab: TaskTabs.BASELINE,
        stageNum: result.baseline.stages[0].num,
        taskNum: result.baseline.stages[0].tasks[0].num,
    });
    const [detailedTab, setDetailedTab] = useState(DetailedTabs.QUERY);

    const axis = {
        data: [],
        scale: result.scale,
        axis: true,
    }

    const comparedAxis = {
        data: [],
        scale: result.compared.summary.time.total.value,
        axis: true,
    }

    const comparedCaption = (
        (result.sameAsBaseline ? "Baseline " : "Compared ") +
        (result.comparedLog ? "Log File " : " ") +
        (result.withApu ? "with APU" : "")
    );

    const gotoStage = (stageNum) => {
        setStageNum(stageNum);
        setDetailedTab(DetailedTabs.STAGE);
    };

    const gotoTask = (tab, stageNum, taskNum) => {
        setTask({
            ...task,
            tab,
            stageNum,
            taskNum,
        });
        setDetailedTab(DetailedTabs.TASK);
    };

    return <>
        <div className="results">
            {tab === Tabs.DETAILED && <div className="detailed-nav">
                <div className={"detailed-nav-item" + (detailedTab === DetailedTabs.QUERY ? " active" : "")} onClick={() => setDetailedTab(DetailedTabs.QUERY)}>
                    <div className="number">01</div>&nbsp;&nbsp;Query Breakdown</div> &nbsp;
                <div className={"detailed-nav-item" + (detailedTab === DetailedTabs.STAGE ? " active" : "")} onClick={() => setDetailedTab(DetailedTabs.STAGE)}>
                    <div className="number">02</div>&nbsp;&nbsp;Stage Breakdown</div> &nbsp;
                <div className={"detailed-nav-item" + (detailedTab === DetailedTabs.TASK ? " active" : "")} onClick={() => setDetailedTab(DetailedTabs.TASK)}>
                    <div className="number">03</div>&nbsp;&nbsp;Task Breakdown</div> &nbsp;
            </div>}

            {tab === Tabs.OVERVIEW && <Summary
                comparedCaption={comparedCaption}
                axis={axis}
                result={result}
                setModal={setModal}
            />}
            {tab === Tabs.DETAILED && detailedTab === DetailedTabs.QUERY && <QueryBreakdown
                result={result}
                caption={"Baseline"}
                comparedCaption={comparedCaption}
                axis={axis}
                comparedAxis={comparedAxis}
                setModal={setModal}
                gotoStage={gotoStage}
            />}

            {tab === Tabs.DETAILED && detailedTab === DetailedTabs.STAGE && <StageBreakdown
                result={result}
                caption={"Baseline"}
                comparedCaption={comparedCaption}
                stageNum={stageNum}
                setStageNum={setStageNum}
                setModal={setModal}
                reassembly={true}
                gotoTask={gotoTask}
            />}

            {tab === Tabs.DETAILED && detailedTab === DetailedTabs.TASK && <TaskBreakdown
                result={result}
                caption={"Baseline"}
                comparedCaption={comparedCaption}
                task={task}
                setModal={setModal}
                gotoTask={gotoTask}
            />}
        </div>
    </>
}
