import React, { useState } from 'react'
import { msToHumanReadable, bytesToString } from '../../utils'
import { Strings } from 'waschema';

const fmt = {
    'MS': str => msToHumanReadable(str, true),
    'BYTES': bytesToString,
}

export function toTableRow(metric, caption, expands, nested) {
    return {
        caption,
        value: metric.value,
        fmt: fmt[metric.unit],
        info: metric.info,
        expands,
        nested,
    }
}

export function MetricsTable({ rows, comparedRows, caption, comparedCaption, setModal, expandAll, tableData, clickFn }) {
    const showInfo = (e, title, item) => {
        e.stopPropagation();
        setModal({
            content: <div>
                <h1>{title}</h1>
                <h3>How Value is Calculated?</h3>
                {item.text.map(str => Strings[str] || "").join("\n").split('\n').map((p, ix) => <p key={ix}>{p}<br /></p>)}
                <br />
                {item.assumptions?.length ? <>
                    <hr />
                    <h3>Assumptions</h3>
                    {item.assumptions.map((a, ix) => <p key={ix}>{Strings[a] || ""}<br /><br /></p>)}
                </> : null}
                <button onClick={() => setModal(null)}>Ok</button>
            </div>
        })
    }

    if (tableData && !rows) {
        rows = tableData.rows.map(metrics => metrics.map(({ caption, unit, clickArgs, values }) => ({
            caption,
            values,
            clickArgs,
            fmt: fmt[unit],
        })))
    }

    let collapses = {};
    let nestings = [];
    let notNestedCounter = 0;
    let oddRows = {};
    let initialExpanded = {};
    rows.forEach((row, ix) => {
        if (row.expands) initialExpanded[row.expands] = expandAll || false;
        if (!row.nested) {
            if (++notNestedCounter % 2 === 1) oddRows[ix] = true;
            return nestings = [];
        }
        if (nestings[nestings.length - 1] !== row.nested) {
            const ix = nestings.indexOf(row.nested);
            if (ix >= 0) {
                nestings = nestings.slice(0, ix + 1);
            } else {
                nestings.push(row.nested);
            }
        }
        nestings.forEach((n, ix) => {
            collapses[n] = collapses[n] || new Set();
            nestings.slice(ix).forEach(nn => collapses[n].add(nn));
        });
        row.level = nestings.length;
    })

    const [expanded, setExpanded] = useState(initialExpanded);

    const toggleExpanded = (row, ix) => {
        if (!row.expands) return;
        if (expanded[row.expands]) {
            let collapse = {
                [row.expands]: false
            };
            collapses[row.expands].forEach(n => collapse[n] = false);
            setExpanded({ ...expanded, ...collapse });
        } else {
            setExpanded({ ...expanded, [row.expands]: true });
        }
    }

    return <table className={"stats metrics " + (tableData ? "" : (comparedRows ? "three-cols" : "two-cols"))}>
        <thead>
            <tr>
                <th>Metric</th>
                {tableData ? <>
                    {tableData.headers.map(header => <th style={{ width: "fit-content" }}>{header}</th>)}
                </> : <>
                    {comparedRows ? <>
                        <th>{caption}</th>
                        <th>{comparedCaption}</th>
                    </> : <th>Value</th>}
                </>
                }
            </tr>
        </thead>
        <tbody> {tableData ? <> {rows.map((metrics, ix) => <tr key={ix} className={oddRows[ix] ? "odd" : ""}>
            <td>{metrics.map(m => m.caption).join(" / ")}</td>
            {tableData.headers.map((_, jx) => <td key={jx} onClick={() => { clickFn(metrics[0].clickArgs[jx]) }}>
                <span className="tooltip" style={{ cursor: "pointer", textDecoration: "underline" }}>
                    <span className="tooltiptext">Task {metrics[0].clickArgs[jx]}</span>
                    {metrics.map(m => m.values[jx] && (m.fmt ? m.fmt(m.values[jx]) : m.values[jx].toLocaleString())).join(" / ")}
                </span>
            </td>)}
        </tr>)}
            {/* values.map(value, jx) => <td key={jx}>{value && (row.fmt ? row.fmt(value) : value.toLocaleString())}</td> */}
        </> : <>
            {rows.map((row, ix) => [row, ix]).filter(([row, _]) => !row.nested || expanded[row.nested]).map(([row, ix]) => <tr className={(row.nested ? "indent indent-" + row.level + " " : "") + (oddRows[ix] ? "odd" : "")} key={ix} onClick={() => toggleExpanded(row, ix)}>
                <td className={row.level ? "indent indent-" + row.level : ""} style={(ix > 0 && row.level >= (rows[ix - 1].level || 0)) ? { borderTop: "none" } : ((ix < rows.length - 1 && row.level > (rows[ix + 1].level || 0)) ? { borderBottom: "none" } : {})}>
                    {row.expands && <span> [{expanded[row.expands] ? "–" : "+"}]
                        &nbsp;</span>}
                    {row.caption}
                </td>
                <td>{row.value && (row.fmt ? row.fmt(row.value) : row.value.toLocaleString())} {row.info && <span onClick={(e) => showInfo(e, `${row.caption} (${caption})`, row.info)} className="info">?</span>}</td>
                {comparedRows && <td>{comparedRows[ix].value && (comparedRows[ix].fmt ? comparedRows[ix].fmt(comparedRows[ix].value) : comparedRows[ix].value.toLocaleString())} {row.info && <span onClick={(e) => showInfo(e, `${row.caption} (${comparedCaption})`, row.info)} className="info">?</span>}</td>}
            </tr>)}
        </>}</tbody>
    </table>
}
